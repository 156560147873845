import React from 'react';
import { Field } from 'redux-form';
import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';

import SelectNewField from '@molecules/selects/SelectNewField';

import { getReasonForCoverDropdownValues } from '@config/reasonForBuyingTempcover';
import ReduxFieldValidation from '@services/formValidation';
import { ComponentType, useComponentId } from '@hooks';

export interface ValueProps {
  isQuoteForMyself: boolean;
}

const ReasonForCover: React.FC<ValueProps> = ({ ...props }) => {
  const creator = useComponentId();

  const { isQuoteForMyself } = props;

  return (
    <Group id={creator(ComponentType.CONTAINER, 'reasonForCover')} data-testid='cover-reason'>
      <SectionTitle id={creator(ComponentType.TITLE, 'reasonForCover')}>
        {`What is the reason ${isQuoteForMyself ? 'you' : 'they'} need temporary cover?`}
      </SectionTitle>
      <Field
        id={creator(ComponentType.FIELD, 'reasonForCover')}
        name='tempcoverReason'
        component={SelectNewField}
        options={getReasonForCoverDropdownValues(isQuoteForMyself)}
        placeholder='Select a reason'
        validate={ReduxFieldValidation.required}
        visibleItemCount={2}
      />
    </Group>
  );
};

export default ReasonForCover;

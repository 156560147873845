import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Button, OrSeparator } from '@rentecarlo/component-library';

import { useOptimizelyTrack, useOptimizelyFlag } from '@hooks';

import QuoteStep from '@templates/QuoteStep';
import OutlineButton from '@atoms/buttons/OutlineButton';
import IndicativePriceInput from '@molecules/inputs/IndicativePriceInput';
import { PaymentMethod } from '@utils/types';

import AnalyticsClient from '@utils/analytics';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Europe/London');

interface StyledProps {
  borderTop?: boolean;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 0px 64px 0px;
  max-width: 400px;
`;

const OrContainer = styled.div`
  margin: 0 32px;
  max-width: 336px;
`;

const DurationCoverButtonContainer = styled.div<StyledProps>`
  max-width: 400px;
  margin: ${({ borderTop }) => (borderTop ? 60 : 24)}px 0 16px;
`;

export interface Props {
  productType: string;
  resetAndGoToDuration: () => void;
  loading: boolean;
  nextAction: (isFastRepurchase: boolean, shouldShowDriverProfile: boolean) => void;
  disableQuickDateSelectionButton: boolean;
  paymentMethod: PaymentMethod;
  endChoiceSelectedValue: string;
  isQuoteForMyself: boolean;
  isFastRepurchase: boolean;
}

const IndicativePrice: React.FC<Props> = ({
  productType,
  resetAndGoToDuration,
  loading,
  nextAction,
  disableQuickDateSelectionButton,
  paymentMethod,
  endChoiceSelectedValue,
  isQuoteForMyself,
  isFastRepurchase,
}) => {
  const sendEvent = useOptimizelyTrack();

  const hidePrice =
    useOptimizelyFlag('TEMP_HIDE_INDICATIVE_PRICE').enabled &&
    paymentMethod !== PaymentMethod.subscription;

  const sendEventAndSubmit = (): void => {
    let shouldShowDriverProfile = false;
    if (productType === 'newdriver' || (productType === 'tc' && paymentMethod === 'subscription')) {
      shouldShowDriverProfile = true;
    }

    if (!isFastRepurchase) {
      shouldShowDriverProfile = false;
    }

    sendEvent('indicative_continue');
    AnalyticsClient.trackEvent('web_cta_indicative_price', { duration: endChoiceSelectedValue });
    nextAction(isFastRepurchase, shouldShowDriverProfile);
  };

  const selectCustomDuration = (): void => {
    sendEvent('indicative_select_custom_duration');
    resetAndGoToDuration();
  };

  const getSubtitle = (): string | null => {
    if (hidePrice) return null;

    const pronoun = isQuoteForMyself ? 'your' : 'their';
    const additionalInfo =
      paymentMethod !== PaymentMethod.subscription
        ? `Please tell us how long ${isQuoteForMyself ? 'you' : 'they'} want cover for.`
        : '';

    return `Based on the information provided, this is an estimated cost for ${pronoun} cover. ${additionalInfo}`;
  };

  return (
    <QuoteStep
      id='indicativePrice-component-quoteStep'
      displaySummaryFooter={false}
      stepTitle={`${
        hidePrice
          ? 'Choose your duration'
          : `The estimated quote for ${isQuoteForMyself ? 'your' : 'their'} insurance`
      }`}
      stepSubtitle={getSubtitle()}
    >
      {paymentMethod === PaymentMethod.subscription ? (
        <>
          <IndicativePriceInput loading={loading} isSubscription />
          <DurationCoverButtonContainer borderTop>
            <Button
              id='indicativePrice-button-continueSubscription'
              height={64}
              onClick={() => sendEventAndSubmit()}
              disabled={disableQuickDateSelectionButton || loading}
            >
              Continue
            </Button>
          </DurationCoverButtonContainer>
        </>
      ) : (
        <>
          <IndicativePriceInput loading={loading} hidePrice={hidePrice} />
          <DurationCoverButtonContainer>
            <Button
              id='indicativePrice-button-continue'
              height={64}
              borderWidth={0}
              onClick={() => sendEventAndSubmit()}
              disabled={loading}
            >
              Continue
            </Button>
          </DurationCoverButtonContainer>
          <OrContainer>
            <OrSeparator />
          </OrContainer>
          <ButtonContainer>
            <OutlineButton
              id='indicativePrice-button-selectOwnDuration'
              testId='custom-button'
              onClick={selectCustomDuration}
            >
              Select your own duration
            </OutlineButton>
          </ButtonContainer>
        </>
      )}
    </QuoteStep>
  );
};

export default IndicativePrice;

/* eslint-disable no-nested-ternary */
import Styled, { css } from 'styled-components';
import size from '@config/size';
import colors from '@config/colors';

interface InputProps {
  alwaysCapitalised: boolean;
  alwaysLowerCase: boolean;
  applyPlaceholderStyle: boolean;
  disabled: boolean;
  placeholderStyle: string;
  textColor?: string;
  textStyle: string;
  value: string;
  borderBottomColor: string;
}

export const Container = Styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

export const Input = Styled.input<InputProps>`
  background-color: ${({ disabled }): string =>
    disabled ? colors.grey.background.disabled : colors.white};
  border: 1px solid ${colors.grey.lines.light};
  border-bottom: 2px solid ${({ borderBottomColor }): string => borderBottomColor || 'none'};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 ${colors.shadow};
  color: ${({ textColor }): string => textColor || colors.outerSpace};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  height: 65px;
  padding: 15px;
  line-height: 19px;
  text-transform: ${({ value, alwaysCapitalised, alwaysLowerCase }): string =>
    value === '' || alwaysCapitalised ? 'uppercase' : '' || alwaysLowerCase ? 'lowercase' : ''};
  width: 100%;
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'inherit')};
  transition: all 0.4s ease-in-out;
  ${({ applyPlaceholderStyle, placeholderStyle, textStyle }): string =>
    applyPlaceholderStyle ? placeholderStyle : textStyle}
  &::placeholder {
    color: ${colors.grey.text.light};
    font-weight: normal;
    text-transform: none;
  }
  ${css`
    @media (max-width: ${size.desktopWidth}) {
      padding-right: 60px;
    }
  `}
  &:focus {
    outline: none;
  }
  appearance: none;
  line-height: normal;
`;

export const IconContainer = Styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

import React, { useEffect, useState } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import {
  ConfirmationToken,
  Stripe,
  StripeElements,
  StripeError,
  StripePaymentElement,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import styled from 'styled-components';
import { Button, InformationBox, ConfirmationTextBox } from '@rentecarlo/component-library';

import Group from '@atoms/layout/form/Group';
import DeliveryPreferenceForm from '@organisms/forms/DeliveryPreferenceForm';
import TextModal from '@organisms/modals/TextModal';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import TextInput from '@atoms/inputs/TextInput';
import GreenTick from '@atoms/inputs/GreenTick';
import { useOptimizelyFlag } from '@hooks';

import handleError from '@redux/utils/errorHandler';
import { withStripeHook } from './stripeHook';
import './StripePaymentForm.less';

const ButtonContainer = styled.div``;
const CardFormContainer = styled.div``;

const InputSpacer = styled.div`
  height: 16px;
`;

const CardPolicy = styled.div`
  overflow-y: scroll;
  padding-right: 12px;
`;

const CardPolicyLine = styled.p`
  margin-bottom: 15px;
`;

interface Address {
  line1?: string;
  line2?: string;
  town?: string;
  postcode?: string;
  county?: string;
  country?: string;
}

interface Props {
  billingAddress: Address;
  cardHolderName: string;
  cardHolderEmail: string;
  isCompletePurchaseDisabled: boolean;
  paymentError?: string;
  paymentLoading?: boolean;
  productEmail: string;
  errorCode: string;
  setCardHolderName: React.ChangeEventHandler<HTMLInputElement>;
  setPaymentLoading: (loading: boolean) => void;
  showBillingAddress: boolean;
  checkDurationHasChanged: () => boolean;
  stripeClientSecret: string | null;
  stripeClientStatus: string | null;
  pollForQuoteStatus: () => void;
  stripeError: (error: StripeError) => void;
  billingAddressPopulated: boolean;
  stripe: Stripe;
  elements: StripeElements;
  enableCompletePurchase: boolean;
  resetPaymentError: () => void;
  sendGAEventForCVCError: (label: string) => void;
  isSubscription: boolean;
  checkSubscriptionIsInPast: () => boolean;
  productType: string;
  completeFinalCheck: (confirmationToken: ConfirmationToken) => void;
  checksPassed: boolean;
  stateConfirmationToken?: string;
}

let element: StripePaymentElement | undefined;

const StripePaymentForm: React.FC<Props> = ({
  paymentLoading,
  setPaymentLoading,
  checkDurationHasChanged,
  billingAddress,
  stripeClientSecret,
  stripeClientStatus,
  cardHolderName,
  cardHolderEmail,
  pollForQuoteStatus,
  stripeError,
  stripe,
  elements,
  resetPaymentError,
  billingAddressPopulated,
  enableCompletePurchase,
  errorCode,
  isSubscription,
  setCardHolderName,
  productEmail,
  paymentError,
  checkSubscriptionIsInPast,
  productType,
  completeFinalCheck,
  checksPassed,
}) => {
  const [isCardValid, setIsCardValid] = useState(false);
  const [showTextModal, setShowTextModal] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const hideDocs = useOptimizelyFlag('TEMP_MOVE_PAPER_DOCS').enabled;

  const formErrorHandler = (err: Error) => {
    handleError(err);
    setPaymentLoading(false);
  };

  const handleNextAction = async () => {
    if (!stripeClientSecret) {
      // we need the secret to process any follow up actions
      return;
    }

    if (stripeClientStatus === 'requires_action') {
      const { error } = await stripe.handleNextAction({ clientSecret: stripeClientSecret });

      if (error) {
        stripeError(error);
        return;
      }
    }

    pollForQuoteStatus();
  };

  const handleConfirm = async () => {
    if (!element) return;

    let address: Address;
    address = {};

    if (billingAddress) {
      address = billingAddress;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: {
            name: cardHolderName,
            email: cardHolderEmail,
            address: {
              line1: address.line1,
              line2: address.line2,
              city: address.town,
              postal_code: address.postcode,
              state: address.county,
              country: 'GB',
            },
          },
        },
      },
    });

    if (error) {
      handleError(error);
      return;
    }

    completeFinalCheck(confirmationToken);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (paymentLoading) {
      handleError(new Error('Stopped duplicate payment request'));
      return;
    }

    setPaymentLoading(true);

    const durationHasChanged = isSubscription
      ? checkSubscriptionIsInPast()
      : checkDurationHasChanged();

    if (durationHasChanged) {
      setPaymentLoading(false);
      return;
    }

    try {
      handleConfirm();
    } catch (e) {
      formErrorHandler(e as Error);
    }
  };

  const handleCardFill = (event: StripePaymentElementChangeEvent) => {
    if (!event.complete && paymentError) {
      resetPaymentError();
    }
    setIsCardValid(event.complete);
  };

  const handleCardReady = (elementInstance: StripePaymentElement) => {
    element = elementInstance;
  };

  const containsNumber = (string: string) => {
    if (!string) return null;
    return /\d+/.test(string);
  };

  const handleShowTextModal = (): void => {
    setShowTextModal(true);
  };

  const handleHideTextModal = (): void => {
    setShowTextModal(false);
  };

  const isCardHolderNameValid = () => {
    return cardHolderName && cardHolderName.length > 2 && !containsNumber(cardHolderName);
  };

  const isPaymentCardValid = () => {
    return isCardValid;
  };

  const isCompletePurchaseDisabled = () => {
    if (enableCompletePurchase) {
      return false;
    }
    return !(
      isCardHolderNameValid() &&
      isPaymentCardValid() &&
      errorCode !== 'incorrect_cvc' &&
      !paymentLoading &&
      billingAddressPopulated
    );
  };

  useEffect(() => {
    if (checksPassed) handleNextAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checksPassed]);

  const options: StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      radios: false,
      spacedAccordionItems: true,
    },
    fields: {
      billingDetails: {
        name: 'auto',
        email: 'auto',
        phone: 'auto',
        address: 'never',
      },
    },
  };

  return (
    <CardFormContainer id='payment-container-payByCardContainer'>
      <Group>
        <SectionTitle id='payment-title-stripePayment'>Cardholder name</SectionTitle>
        <TextInput
          placeholder='Full name'
          id='payment-input-fullName'
          value={cardHolderName}
          onChange={setCardHolderName}
          isValid={
            cardHolderName !== '' && !containsNumber(cardHolderName) && cardHolderName.length > 2
          }
          type='text'
          showError={false}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
        />
        <InputSpacer />
        {!inputFocused && cardHolderName !== '' && cardHolderName.length < 3 && (
          <InformationBox id='payment-text-stripePaymentNameErrorLength' type='error'>
            Please enter a valid name.
          </InformationBox>
        )}
        {!inputFocused && containsNumber(cardHolderName) && cardHolderName.length > 2 && (
          <InformationBox id='payment-text-stripePaymentNameErrorNumbers' type='error'>
            Your name cannot contain any numbers.
          </InformationBox>
        )}
      </Group>
      <Group>
        <SectionTitle id='payment-title-stripePaymentDetails'>Payment details</SectionTitle>
        <GreenTick
          id='payment-icon-stripePaymentDetails'
          showTick={isCardValid && !paymentError}
          mobileRightMargin='-50px'
        >
          <PaymentElement
            options={options}
            onChange={(event) => handleCardFill(event)}
            onReady={(elementInstance) => handleCardReady(elementInstance)}
          />
        </GreenTick>
      </Group>

      {productType !== 'newdriver' && !hideDocs && (
        <Group>
          <DeliveryPreferenceForm />
        </Group>
      )}
      <TextModal
        title='About the Payment'
        show={showTextModal}
        close={(): void => {
          handleHideTextModal();
        }}
      >
        <CardPolicy>
          <CardPolicyLine id='payment-text-stripePaymentPolicyOne'>
            By adding these card details, you are authorising Veygo to collect the full premium for
            your policy immediately from the card provided. You also confirm that you have
            permission to use this card. We reserve the right to charge this card for any further
            policy payments in the future if we are not provided with alternative payment details.
          </CardPolicyLine>
          <CardPolicyLine id='payment-text-stripePaymentPolicyTwo'>
            The contract of insurance between Veygo and you will be informed as soon as we have
            authorisation of payment from your card company. On clicking ‘Complete Purchase’ we will
            show you a new page confirming your insurance. Until you see this page, you do not have
            insurance cover. We will also confirm your policy details by email* and provide a link
            to view your Certificate of Motor Insurance online.
          </CardPolicyLine>
          <CardPolicyLine id='payment-text-stripePaymentPolicyThree'>
            *If you do not receive your email within 24 hours please contact us at:{' '}
            <a id='payment-link-stripePaymentPolicy' href={`${productEmail}`}>
              {productEmail}
            </a>
          </CardPolicyLine>
        </CardPolicy>
      </TextModal>

      <ConfirmationTextBox
        text='By completing this purchase, you are agreeing to our'
        actionText='Payment Terms'
        action={() => handleShowTextModal()}
      />

      <ButtonContainer>
        <Button
          id='payment-button-stripePaymentCompletePurchase'
          testId='completePurchaseButton'
          borderWidth={0}
          height={64}
          disabled={isCompletePurchaseDisabled()}
          onClick={handleSubmit}
        >
          Complete Purchase
        </Button>
      </ButtonContainer>
    </CardFormContainer>
  );
};

export default withStripeHook(StripePaymentForm);

import React, { useEffect } from 'react';

import { Field, reduxForm, InjectedFormProps, destroy } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { useAppSelector, useOptimizelyTrack } from '@hooks';
import { saveAndGoTo, setIsFastPurchase } from '@reducer/quote/save.actions';
import { RootState } from '@redux/reducer';
import camelToSnakeCase from '@services/camelToSnakeCase';
import validation from '@services/formValidation';

import { setPaymentMethod } from '@reducer/quote/product.actions';

import QuoteStep from '@templates/QuoteStep';
import PaymentMethodSelector from '@molecules/PaymentMethodSelector';
import { PaymentMethod } from '@utils/types';

import styled from 'styled-components';
import Group from '@atoms/layout/form/Group';
import PriceTagBox from '@atoms/blocks/PriceTagBox';
import { useHistory } from 'react-router-dom';
import { setShouldShowSideBar } from '@redux/reducer/account/customer.actions';
import ReasonForCover from '@organisms/fields/ReasonForCover';
import { setOtherReasonForCover, setReasonForCover } from '@redux/reducer/quote/tempcover.actions';
import { resetQuote } from '@redux/reducer/quote/actions';
import { togglePrivacyPolicy } from '@redux/reducer/quote/legal.actions';
import { PriceTagContainer } from './assets/styles';

const TempCoverReasonContainer = styled.div`
  width: 100%;
  & > ${Group} {
    margin-bottom: 0;
  }
`;

const ChooseYourCover: React.FC<InjectedFormProps> = ({ handleSubmit, valid }) => {
  const sendEvent = useOptimizelyTrack();
  const history = useHistory();
  const isFastRepurchase = useAppSelector((state) => state.quote.save.isFastRepurchase);
  const dispatch = useDispatch();
  const product = useSelector((state: RootState) => state.quote.product);
  const isQuoteForMyself = useAppSelector((state) => state.account.customer.isQuoteForMyself);
  const paymentMethod = useAppSelector(
    (state) => state.form.chooseYourCoverForm?.values?.paymentMethod,
  );
  const tempcoverModalOpen = useSelector((state: RootState) => state.quote.user.tempcoverModalOpen);

  useEffect(() => {
    return history.listen((newLocation, action) => {
      if (action === 'POP' && isFastRepurchase && newLocation.pathname === '/quote-start') {
        dispatch(setIsFastPurchase(false));
        dispatch(setShouldShowSideBar(true));
        dispatch(resetQuote());
        dispatch(destroy('quoteStartForm'));
        dispatch(togglePrivacyPolicy());
      }
    });
  }, [dispatch, isFastRepurchase, history]);

  const requiresReasonForCover =
    paymentMethod === 'oneOff' && product.productType === 'tc' && isFastRepurchase;

  return (
    <QuoteStep
      id='chooseYourCover-component-quoteStep'
      stepTitle={isQuoteForMyself ? 'Choose your cover...' : 'Choose their cover...'}
      showButton={!tempcoverModalOpen}
      nextDisabled={!valid}
      nextLabel='Continue'
      height={tempcoverModalOpen ? '1500' : ''}
      nextAction={handleSubmit((values: Record<string, string>) => {
        const updatedPaymentMethod = values.paymentMethod as PaymentMethod;
        if (product.paymentMethod !== updatedPaymentMethod) {
          sendEvent(`choose_your_cover_${camelToSnakeCase(values.paymentMethod)}`);
          dispatch(setPaymentMethod(updatedPaymentMethod));
        }
        dispatch(setReasonForCover(values.tempcoverReason));
        dispatch(setOtherReasonForCover(values.tempcoverAltReason || ''));
        dispatch(saveAndGoTo('indicative-price'));
      })}
    >
      <Field
        id='chooseYourCover-field-paymentMethod'
        name='paymentMethod'
        component={PaymentMethodSelector}
        validate={[validation.isRequired]}
      />

      {requiresReasonForCover && (
        <TempCoverReasonContainer data-testid='tempcoverReasonContainer'>
          <ReasonForCover />
        </TempCoverReasonContainer>
      )}
      {product.productType === 'ldp' && (
        <PriceTagContainer>
          <PriceTagBox id='chooseYourCover-text-learnerDiscountMessaging' alignIcon='self-start'>
            When you pass and upgrade to a full licence, as a Veygo learner, you get a 10% discount
            on any policies you buy over the next year. T&Cs apply.
          </PriceTagBox>
        </PriceTagContainer>
      )}
    </QuoteStep>
  );
};

export default reduxForm({
  form: 'chooseYourCoverForm',
})(ChooseYourCover);

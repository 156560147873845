import analyticsClient from '@utils/analytics';
import React from 'react';

const TrackingInput: React.FC<{ children: React.ReactNode; fieldId: string }> = ({
  children,
  fieldId,
}) => {
  const [startTime, setStartTime] = React.useState<number | null>(null);

  const handleOnFocus = (e: React.FocusEvent) => {
    if (e.target.tagName === 'INPUT') {
      setStartTime(Date.now());
    }
  };

  const handleOnBlur = () => {
    if (startTime) {
      const endTime = Date.now();
      const timeSpent = endTime - startTime;
      setStartTime(null);

      analyticsClient.trackEvent('web_field_interaction', {
        field_id: fieldId,
        time_spent: timeSpent / 1000,
      });
    }
  };

  return (
    <div onFocus={handleOnFocus} onBlur={handleOnBlur}>
      {children}
    </div>
  );
};

export default TrackingInput;

import { useSelector } from 'react-redux';
import { useDecision, OptimizelyDecision } from '@optimizely/react-sdk';

import { optimizelyOverrideAttributes } from '@reselectors/optimizely';
import { decideOptimizelyProduct } from '@utils/optimizelyProduct';
import analyticsClient from '@utils/analytics';

export interface OptimizelyFlag extends OptimizelyDecision {
  clientReady: boolean;
}

const useOptimizelyFlag = (featureFlag: string): OptimizelyFlag => {
  const overrideAttributes = useSelector(optimizelyOverrideAttributes);
  const optimizelyProduct = decideOptimizelyProduct();

  const [decision, clientReady] = useDecision(
    featureFlag,
    {},
    {
      overrideUserId: overrideAttributes.user_identity,
      overrideAttributes: { ...overrideAttributes, optimizely_product: optimizelyProduct },
    },
  );

  analyticsClient.trackFeatureFlagDecision(featureFlag, decision.enabled);

  return { ...decision, clientReady };
};

type FlagVariations = Record<string, { flagName: string; variationKeys: string[] }>;

const flagVariations: FlagVariations = {};

export const useOptimizelyFlagVariation = (feature: keyof typeof flagVariations): boolean => {
  const flag = useOptimizelyFlag(flagVariations[feature].flagName);
  return flag.enabled && flagVariations[feature].variationKeys.includes(flag.variationKey || '');
};

export default useOptimizelyFlag;

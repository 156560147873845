import React from 'react';
import { SectionTitle } from '@config/importantInformation';
import SelectNewField from '@molecules/selects/SelectNewField';
import { Field } from 'redux-form';
import ReduxFieldValidation from '@services/formValidation';
import TextInputField from '@atoms/inputs/TextInputField';
import SectionTitleWithTooltip from '@atoms/titles/SectionTitleWithTooltip';
import { Tooltip } from '@rentecarlo/component-library';
import ToggleSelector from '@molecules/inputs/ToggleSelectorField';
import { TooltipText, Spacer } from '../assets/styles';

interface Props {
  input: {
    onChange: (val: string) => void;
    value: string;
  };
  alternativeInsurers: Array<{ name: string; value: string }>;
  otherInsuranceSelected: boolean;
  getInsurersName: string;
  isDriverOwner: boolean;
  isQuoteForMyself: boolean;
}

const InsuranceProviders: React.FC<Props> = ({
  input: { onChange },
  alternativeInsurers,
  otherInsuranceSelected,
  getInsurersName,
  isDriverOwner,
  isQuoteForMyself,
}: Props) => {
  return (
    <>
      <SectionTitle id='car-title-hasInsuranceField'>
        Select the insurance provider from the list
      </SectionTitle>
      <Field
        id='car-field-mainInsurer'
        name='mainInsurer'
        component={SelectNewField}
        options={[
          { name: "There isn't a main insurance policy", value: 'false' },
          ...alternativeInsurers,
        ]}
        placeholder='Insurance provider'
        onChange={(selectedValue: string) => {
          const newHasInsuranceValue = selectedValue !== 'false' ? 'true' : 'false';
          onChange(newHasInsuranceValue);
        }}
      />
      {otherInsuranceSelected && (
        <Spacer>
          <Field
            name='alternateInsurer'
            id='car-field-alternateInsurer'
            component={TextInputField}
            placeholder='Please type the name of your insurer'
            validate={[ReduxFieldValidation.required, ReduxFieldValidation.validAlternativeInsurer]}
            optional
          />
        </Spacer>
      )}
      {isDriverOwner &&
        ((getInsurersName && getInsurersName !== 'false') || otherInsuranceSelected) && (
          <>
            <Spacer space='27px' />
            <SectionTitleWithTooltip
              title={`Are ${isQuoteForMyself ? 'you' : 'they'} a named driver with ${
                getInsurersName || ''
              }?`}
              tooltip={
                <Tooltip
                  title='What does this mean?'
                  modalTitle='What does this mean?'
                  tooltipDirection='right'
                  width='200'
                >
                  <TooltipText id='car-text-hasInsuranceFieldTooltip'>
                    If you are insured to drive this car through {getInsurersName} as your name is
                    down on the policy, you're a named driver. This is also known as an additional
                    driver.
                  </TooltipText>
                </Tooltip>
              }
            />
            <Field
              name='isNamedDriver'
              id='car-field-isNamedDriver'
              component={ToggleSelector}
              validate={[ReduxFieldValidation.required]}
              groupName='namedDriver'
              marginBottom='10px'
            />
          </>
        )}
    </>
  );
};

export default InsuranceProviders;

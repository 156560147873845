import type { ValidPropertyType } from '@amplitude/analytics-types';
import AmplitudeClient from './amplitude';
import { getDecidedFeatures } from './optimizely';

type ClientConfig = { enabled: boolean; apiKey: string };

type InitSettings = { amplitude: ClientConfig };

type GlobalProperties = {
  product_type: string;
  is_subscription: boolean;
  is_logged_in: boolean;
  login_point: string;
  quote_uuid: string;
};

export class AnalyticsClient {
  private amplitudeClient: AmplitudeClient = new AmplitudeClient();

  private getGlobalProperties?: () => GlobalProperties;

  public async init(setup: InitSettings): Promise<void> {
    await this.amplitudeClient.init(setup.amplitude.enabled, setup.amplitude.apiKey);
  }

  public addGlobalProperties(globalPropertiesCallback: () => GlobalProperties): void {
    this.getGlobalProperties = globalPropertiesCallback;
  }

  public trackEvent(eventName: string, eventProperties?: object): void {
    const properties = {
      ...eventProperties,
      ...this.getGlobalProperties?.(),
    };

    if (properties?.is_logged_in === false) {
      this.unSetUserId();
    }

    this.amplitudeClient.trackEvent(eventName, properties);
  }

  public setUserId(userId: string): void {
    this.amplitudeClient.setUserId(userId);
  }

  public unSetUserId(): void {
    this.amplitudeClient.unSetUserId();
  }

  public trackFeatureFlagDecision(flagName: string, enabled: boolean): void {
    if (!/^TEMP_/i.test(flagName)) return;

    const previouslyTrackedFeatures = getDecidedFeatures();

    const featureList = enabled
      ? previouslyTrackedFeatures.enabledFeatures
      : previouslyTrackedFeatures.disabledFeatures;
    const featureAlreadyTracked = featureList.includes(flagName);

    if (!featureAlreadyTracked) {
      featureList.push(flagName);
      sessionStorage.setItem(
        'amplitude_tracked_features',
        JSON.stringify(previouslyTrackedFeatures),
      );
      this.trackEvent('web_feature_flags', previouslyTrackedFeatures);
    }
  }

  public trackPurchase(product: string, price: number, eventProperties = {}): void {
    this.amplitudeClient.trackPurchase(product, price, eventProperties);
  }

  public trackUserProperty(property: string, value: ValidPropertyType): void {
    this.amplitudeClient.trackUserProperty(property, value);
  }
}

const analyticsClient = new AnalyticsClient();

export default analyticsClient;

import React from 'react';

import { PageModal, IconList } from '@rentecarlo/component-library';
import TealQuestionMark from '@assets/teal-question-mark-icn.svg';
import BlackTick from '@assets/black-tick.svg';
import { ComponentType, useComponentId } from '@hooks';
import styled from 'styled-components';
import colors from '@config/colors';

interface Props {
  show: boolean;
  close: () => void;
  isQuoteForMyself: boolean;
}

const Image = styled.img`
  align-self: center;
`;

const Title = styled.h2`
  color: ${colors.black};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Graphie;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: -0.26px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
`;

const Text = styled.p`
  color: ${colors.black};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'proxima-soft';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.31px;
`;

const Description = styled(Text)`
  text-align: center;
`;

const TextHeaders = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.23px;
  color: ${({ theme }) => theme.textPrimary};
`;

export const SeamlessExcessModal: React.FC<Props> = ({ show, close, isQuoteForMyself }) => {
  const closeText = 'Close';
  const idCreator = useComponentId();
  return (
    <PageModal close={close} closeText={closeText} show={show}>
      <Container>
        <Image src={TealQuestionMark} />
        <Title id={idCreator(ComponentType.TITLE, 'PaperDocsModalTitle')}>
          Why will {isQuoteForMyself ? 'my' : 'their'} excess increase when{' '}
          {isQuoteForMyself ? 'I' : 'they'} pass?
        </Title>
        <Description id={idCreator(ComponentType.TEXT, 'PaperDocsModalDescription')}>
          'Driving experience' is one of the key risk factors that influence the excess on{' '}
          {isQuoteForMyself ? 'your' : 'their'} policy. That’s why, for the first year of solo
          driving while {isQuoteForMyself ? 'you' : 'they'} build up{' '}
          {isQuoteForMyself ? 'your' : 'their'} experience the compulsory excess will be £750.
          <br />
          <br />
          But we can help {isQuoteForMyself ? 'you' : 'them'} save in other ways:
        </Description>
        <IconList
          icon={BlackTick}
          id='SeamlessPassPriceModal-iconList'
          items={[
            <Text id='importantInformation-text-seamlessModalInfoListOne'>
              <TextHeaders>A lower monthly rate</TextHeaders>A higher excess means we can charge{' '}
              {isQuoteForMyself ? 'you' : 'them'} less every month.
            </Text>,
            <Text id='importantInformation-text-seamlessModalInfoListTwo'>
              <TextHeaders>
                No price rise when {isQuoteForMyself ? 'you' : 'they'} first pass
              </TextHeaders>
              We’ll charge {isQuoteForMyself ? 'you' : 'them'} the same for the first month after{' '}
              {isQuoteForMyself ? 'you' : 'they'} pass as {isQuoteForMyself ? 'you' : 'they'} paid
              while {isQuoteForMyself ? 'you' : 'they'} were learning.
            </Text>,
            <Text id='importantInformation-text-seamlessModalInfoListThree'>
              <TextHeaders>Drive safe, save money</TextHeaders>
              {isQuoteForMyself ? 'Use' : 'They can use'} our app to track{' '}
              {isQuoteForMyself ? 'your' : 'their'} driving, follow the tips and get rewarded with
              money off as {isQuoteForMyself ? 'you' : 'they'} improve.
            </Text>,
          ]}
        />
      </Container>
    </PageModal>
  );
};

export default SeamlessExcessModal;

import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '@redux/reducer';
import rejectionService from '@services/rejection';

import Header from '@organisms/header/Header';
import StepTitle from '@atoms/titles/pageTitles/StepTitle';
import { MainContainer, ContentContainer, Content, InnerContent } from '@templates/Main';

import RejectionFooter from '@molecules/asides/RejectionFooter.container';
import NewDriverCrossSellModal from '@organisms/modals/NewDriverCrossSellModal';
import { InformationBox } from '@rentecarlo/component-library';

import { AlternativeProducts } from '@redux/reducer/quote/alternativeProducts';
import { RejectionReasons } from '@redux/reducer/quote/saveTypes';
import { ProductType } from '@utils/types';
import { useOptimizelyFlag } from '@hooks';
import analyticsClient from '@utils/analytics';
import { Page } from '../../../ui-tools';

import useResetQuote from './useResetQuote';
import { Image, ImportantBoxContainer } from './styles';
import NewDriverCrossSell from './NewDriverCrossSell';
import TempCoverCrossSell from './TempCoverCrossSell';

interface Props {
  restrictedPeriod: boolean;
  rejectionReasons: RejectionReasons[];
  fetchRestrictedPeriods: () => void;
  location: { pathname: string };
  product: ProductType;
  isSubscription: boolean;
  alternativeProducts: AlternativeProducts;
  updateQuoteToNewDriver: () => void;
  isQuoteForMyself: boolean;
  updateQuoteToTempCover: (price: number, selectDuration: boolean) => void;
}

const Rejection = ({
  restrictedPeriod,
  rejectionReasons,
  fetchRestrictedPeriods,
  location,
  alternativeProducts,
  updateQuoteToNewDriver,
  isQuoteForMyself,
  product,
  updateQuoteToTempCover,
}: Props): JSX.Element => {
  // Stateful page rejection reasons so we can reset the quote
  // while keeping the page relevant to the rejection that led us here
  const [pageRejectionReasons, setPageRejectionReasons] = useState<RejectionReasons[]>();
  const hasRejectionReasons = useRef(false);
  const tempCoverCrossSellEnabled = useOptimizelyFlag('TEMP_CROSS_SELL_TEMP_COVER').enabled;
  const [newDriverCrossSellModalIsOpen, setNewDriverCrossSellModalIsOpen] = useState(false);
  const isDriverOwner = useSelector((state: RootState) => state.quote.driver.isDriverOwner);
  const newDriverCrossSellResults = alternativeProducts?.newdriver ?? null;
  const temporaryCoverCrossSellResults = alternativeProducts?.tempcover ?? null;

  const [redirectToStartHandler] = useResetQuote();

  useEffect(() => {
    if (hasRejectionReasons.current) return;
    if (!rejectionReasons) {
      // Customer may have refreshed after landing on the page.
      // Their quote and rejection reasons will have reset at this point.
      // Redirect them to the start if they have no reasons to show.
      redirectToStartHandler();
      return;
    }
    fetchRestrictedPeriods();
    setPageRejectionReasons(rejectionReasons);
    hasRejectionReasons.current = true;
  }, [rejectionReasons, redirectToStartHandler, fetchRestrictedPeriods]);

  const showNewDriverCrossSell =
    newDriverCrossSellResults?.price &&
    product !== 'newdriver' &&
    rejectionService.getEligibleForCrossSell(pageRejectionReasons);
  const showTemporaryCoverCrossSell =
    !showNewDriverCrossSell && temporaryCoverCrossSellResults?.price && tempCoverCrossSellEnabled;
  const showDefaultRejection = !showNewDriverCrossSell && !showTemporaryCoverCrossSell;
  useEffect(() => {
    const crossSellDisplayed = () => {
      if (showNewDriverCrossSell) {
        return 'NDS';
      }
      if (showTemporaryCoverCrossSell) {
        return isDriverOwner ? 'OTC' : 'DTC';
      }
      return 'none';
    };

    analyticsClient.trackEvent('web_quote_rejection_cross_sell', {
      cross_sell_displayed: crossSellDisplayed(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeNewDriverCrossSellModal = () => {
    setNewDriverCrossSellModalIsOpen(false);
  };

  return (
    <MainContainer id='rejection-container'>
      <NewDriverCrossSellModal
        show={newDriverCrossSellModalIsOpen}
        onClose={closeNewDriverCrossSellModal}
        action={updateQuoteToNewDriver}
      />
      <Header pathname={location.pathname} />
      <ContentContainer>
        <Content>
          <InnerContent id='rejection-container-innerContent'>
            {showNewDriverCrossSell && (
              <NewDriverCrossSell
                isQuoteForMyself={isQuoteForMyself}
                setNewDriverCrossSellModalIsOpen={setNewDriverCrossSellModalIsOpen}
                newDriverCrossSellResults={newDriverCrossSellResults}
              />
            )}
            {showTemporaryCoverCrossSell && (
              <TempCoverCrossSell
                isQuoteForMyself={isQuoteForMyself}
                tempCoverCrossSellResults={temporaryCoverCrossSellResults}
                updateQuoteToTempCover={(selectDuration) =>
                  updateQuoteToTempCover(temporaryCoverCrossSellResults.price, selectDuration)
                }
              />
            )}
            {showDefaultRejection && (
              <>
                <StepTitle
                  title={`Sorry, we can't offer ${isQuoteForMyself ? 'you' : 'them'} insurance`}
                  paddingTop='50px'
                />
                <Image src={rejectionService.getImage(pageRejectionReasons)} />
                <Page id='rejection-component-page' borderTop={false}>
                  {rejectionService.getSubtitle(pageRejectionReasons, restrictedPeriod)}
                </Page>
                <ImportantBoxContainer>
                  <InformationBox id='rejection-text-finalDecision' type='important'>
                    Please note that our Customer Care agents are not able to change this decision
                    or provide specific reasoning.
                  </InformationBox>
                </ImportantBoxContainer>
                <RejectionFooter />
              </>
            )}
          </InnerContent>
        </Content>
      </ContentContainer>
    </MainContainer>
  );
};

export default Rejection;

/* eslint-disable camelcase */
import ReactGA from 'react-ga';
import { select } from 'redux-saga/effects';
import { sha256 } from 'js-sha256';
import { RootState } from '@redux/reducer';
import { PaymentComplete } from './ecommerceTrackingTypes';
import { stringToFloat } from './convertString';
import { trackEvent } from './clients/optimizely';
import { calculateDurationInHours } from './date';

const CAR_SHARING = '/car-sharing';
const LEARNER_DRIVER = '/learner-driver';

const PRODUCT_URL_COMPONENT = {
  csi: CAR_SHARING,
  tc: CAR_SHARING,
  ldp: LEARNER_DRIVER,
};

export const initGA = (): void => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_USER_ACCOUNT_ID);
  ReactGA.plugin.require('ecommerce');
};

export const logPageView = (product: 'csi' | 'tc' | 'ldp'): void => {
  const page = window.location.pathname;
  const product_page = `${PRODUCT_URL_COMPONENT[product]}${page}`;
  ReactGA.set({
    page: product_page,
    product,
  });
  window.dataLayer.push({
    // GTM
    event: 'pageView',
    page,
    product_url: product_page,
    product,
  });
};

const getSku = (product: string) => {
  if (product === 'car-sharing') {
    return 'csi1';
  }
  if (product === 'learner-driver') {
    return 'ldp1';
  }
  return 'unknown';
};

export const logEcommerceEvent = (quoteUuid: string, price: string, product: string): void => {
  ReactGA.plugin.execute('ecommerce', 'addItem', {
    id: quoteUuid,
    name: product,
    sku: getSku(product),
    price,
    category: product,
    quantity: '1',
  });

  ReactGA.plugin.execute('ecommerce', 'addTransaction', {
    id: quoteUuid,
    revenue: price,
  });

  ReactGA.plugin.execute('ecommerce', 'send');
  ReactGA.plugin.execute('ecommerce', 'clear');
};

export const logConversionEvent = (
  price: string,
  product: string,
  driverEmail: string,
  isFirstPurchase = '',
  numberOfPolicies = '',
): void => {
  ReactGA.ga('event', 'conversion', {
    price,
    currency: 'GBP',
    product,
  });

  window.dataLayer.push({
    // GTM
    event: 'conversion',
    price,
    currency: 'GBP',
    product,
    driverEmail,
    isFirstPurchase,
    numberOfPolicies,
  });
};

const getHashedValue = (value: string) => {
  return value ? sha256(value.toLowerCase()) : '';
};

const getHashedPhoneNumber = (value: string) => {
  return value ? sha256(value) : '';
};

export const paymentCompleteSelector = (state: RootState): PaymentComplete => {
  const {
    driver,
    car,
    licence,
    duration,
    owner,
    payment,
    save,
    ancillary,
    product,
    importantInformation,
    tempcover,
  } = state.quote;

  return {
    licenceType: licence.type || '',
    loggedIn: true,
    ecommerce: {
      transaction_id: save.referenceNumber || '',
      coupon: payment.discountCode || '',
      shipping: 0,
      currency: 'GBP',
      breakdownAdded: ancillary.breakdownCover || false,
      items: [
        {
          item_id: product.productType || '',
          item_name: licence.type || '',
          index: 0,
          price: save.price || '',
          subscription: product.isSubscription || false,
        },
      ],
    },
    product: {
      duration: duration?.endChoiceSelected?.inHours || null,
      subscription: product.isSubscription || false,
    },
    quote: {
      quoteId: save.uuid || '',
      policyNumber: save.referenceNumber || '',
      excess: importantInformation.excessSelected || null,
      purchased: save.purchased.length > 0,
      hours: duration.hours || null,
      reason: tempcover.reasonForCover || '',
    },
    car: {
      insuranceGroup: car.mainInsurer || '',
      make: car.make || '',
      model: car.model || '',
      transmission: car.transmission || '',
      value: car.value ? car.value.toString() : '',
      year: car.year ? car.year.toString() : '',
    },
    driver: {
      occupation: driver.occupation || '',
      occupationType: driver.occupationType || '',
      email: getHashedValue(driver.email),
      phoneNumber: getHashedPhoneNumber(driver.phoneNumber),
    },
    owner: {
      relationship: owner.relationship || '',
      occupation: owner.occupation || '',
      occupationType: owner.occupationType || '',
      email: getHashedValue(owner.email),
      phoneNumber: getHashedPhoneNumber(owner.phoneNumber),
    },
  };
};

export const logPaymentComplete = (properties: PaymentComplete, screenName: string): void => {
  window.dataLayer.push({
    event: 'logPaymentComplete',
    screenName,
    properties,
  });
};

export const pushReasonForCover = (state: RootState, screenName: string): void => {
  const {
    quote: { driver, car, licence, duration, product, save, owner },
    account: {
      login: { loggedIn },
    },
  } = state;

  const properties = {
    licenceType: licence.type || '',
    loggedIn,
    product: {
      owner: !!(
        owner.firstName &&
        owner.surname &&
        owner.birthdate &&
        owner.email &&
        owner.relationship
      ),
      duration: duration.endChoiceSelected?.inHours || null,
      subscription: product.isSubscription,
    },
    quote: {
      quoteId: save?.uuid || '',
    },
    car: {
      insuranceGroup: car.mainInsurer,
      make: car.make,
      model: car.model,
      transmission: car.transmission,
      value: car.value || '',
      year: car.year || '',
    },
    driver: {
      occupation: driver.occupation,
      occupationType: driver.occupationType,
    },
    owner: {
      relationship: owner.relationship,
      occupation: owner.occupation,
      occupationType: owner.occupationType,
    },
  };

  window.dataLayer.push({
    event: 'insuranceReason',
    screenName,
    properties,
  });
};

export function* trackDuration(): Generator {
  const price = (yield select((state) => state.quote.save.price)) as string;
  const grossWrittenPremium = stringToFloat(price) / 1.12;
  const grossInPence = Math.round(grossWrittenPremium * 100);

  const duration = (yield select((state) => state.quote.duration.hours)) as number;
  const startDateTime = yield select((state) => state.quote.duration.startDateTime);
  const endDateTime = yield select((state) => state.quote.duration.endDateTime);
  const calculatedDurationHours = yield calculateDurationInHours(startDateTime, endDateTime);

  yield trackEvent('track_duration', {
    revenue: grossInPence,
    value: duration || calculatedDurationHours,
  });
}

export const logNewDriverTelematicsToggledEvent = (newValue: boolean): void => {
  window.dataLayer.push({
    event: 'newDriverTelematicsToggled',
    toggled: newValue,
  });
};

export const logNewDriverTelematicsModalOpenedEvent = (): void => {
  window.dataLayer.push({
    event: 'newDriverTelematicsFindOutMoreModalOpened',
  });
};

export const logNewDriverTelematicsMessagingEvent = (bannerText: string): void => {
  window.dataLayer.push({
    event: 'newDriverTelematicsMessagingEvent',
    bannerText,
  });
};

export const logVariableExcessEvent = (excessValue: number, productType: string): void => {
  const product = productType === 'tc' ? 'TEMPCOVER' : productType.toUpperCase();
  ReactGA.event({
    category: `Variable Excess`,
    action: `Click - ${product}`,
    label: `${excessValue}`,
  });
};

export const logNoMainInsuranceEvent = (): void => {
  ReactGA.event({
    category: 'No main insurance',
    action: 'Click - No main insurance LDP owner driver',
    label: 'No Main Insurance Owner LDP',
  });
};

import React from 'react';
import { Chip, P } from '@rentecarlo/component-library';
import SegmentedControl from '@atoms/buttons/SegmentedControl';
import { QuoteRowDetail, QuoteDurationSummary } from '@molecules/blocks/PricingComponents';
import { useComponentId, ComponentType } from '@hooks';
import styled from 'styled-components';

import SubscriptionIcon from '@assets/subscription.svg';
import TelematicsIcon from '@assets/telematics.svg';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/reducer';
import {
  Container,
  Text,
  RowContainer,
  PoundSign,
  PriceText,
  PerMonth,
  ChipRowContainer,
  FirstMonth,
  PassPriceEstimateText,
  FullContainer,
  QuoteDetails,
  OldValue,
  IPTText,
  Divider,
} from './styles';

const TextContainer = styled.div`
  margin: 0 16px;
  font-weight: bold;
`;

type Props = {
  leftActive: boolean;
  handleToggle: () => void;
  renderPromoCode?: string | JSX.Element;
  showPolicyDetails?: boolean;
  renderIPTText?: boolean;
};

const LearnerPriceView: React.FC<{
  learnerPrice: string;
  renderPromoCode?: string | JSX.Element;
  initialPrice?: string;
  renderIPTText?: boolean;
  showPolicyDetails?: boolean;
}> = ({
  learnerPrice,
  showPolicyDetails = false,
  renderPromoCode,
  initialPrice,
  renderIPTText,
}) => {
  const creator = useComponentId();

  return (
    <Container
      id={creator(ComponentType.CONTAINER, `learnerPriceView`)}
      showPolicyDetails={showPolicyDetails}
    >
      <Text id={creator(ComponentType.TEXT, `learnerPriceTitle`)}>
        Monthly payment until you pass
      </Text>
      <RowContainer id={creator(ComponentType.CONTAINER, `learnerPriceContainer`)}>
        <PoundSign id={creator(ComponentType.TEXT, `learnerpoundSign`)}>£</PoundSign>
        <PriceText id={creator(ComponentType.TEXT, `learnerprice`)}>{learnerPrice}</PriceText>
        {initialPrice && parseFloat(learnerPrice) < parseFloat(initialPrice) && (
          <OldValue id='quote-text-quoteSummaryOldQuotePrice'>£{initialPrice}</OldValue>
        )}
        <PerMonth id={creator(ComponentType.TEXT, `learnermonth`)}>/mo</PerMonth>
      </RowContainer>
      <Chip icon={SubscriptionIcon} text='Subscription Policy' />
      <TextContainer>
        <P fontSize={14} id='seamlesslearner-text-noFeeCancelText'>
          Cancel anytime with no fees ✅
        </P>
      </TextContainer>
      {renderPromoCode && renderPromoCode}
      {renderIPTText && (
        <IPTText id={creator(ComponentType.CONTAINER, `iptTextLearner`)}>
          Your price includes insurance premium tax at 12% (where applicable)
        </IPTText>
      )}
    </Container>
  );
};

const PriceAfterPassView: React.FC<{
  learnerPrice: string;
  priceAfterPass: string;
  carDetails?: string;
  showPolicyDetails?: boolean;
}> = ({ learnerPrice, priceAfterPass, showPolicyDetails = false }) => {
  const creator = useComponentId();
  return (
    <Container id='priceAfterPassView' showPolicyDetails={showPolicyDetails}>
      <FirstMonth id={creator(ComponentType.TEXT, `passPriceTitle`)}>
        First month at £{learnerPrice}! 🎉
      </FirstMonth>
      <PassPriceEstimateText id={creator(ComponentType.TEXT, `passPriceEstimateText`)}>
        Your <b>estimated</b> price after first month<sup>*</sup>
      </PassPriceEstimateText>
      <RowContainer id={creator(ComponentType.CONTAINER, `passPriceContainer`)}>
        <PoundSign id={creator(ComponentType.TEXT, `passpoundSign`)}>£</PoundSign>
        <PriceText id={creator(ComponentType.TEXT, `passprice`)}>{priceAfterPass}</PriceText>
        <PerMonth id={creator(ComponentType.TEXT, `passmonth`)}>/mo</PerMonth>
      </RowContainer>
      <ChipRowContainer>
        <Chip icon={TelematicsIcon} text='Telematics' />
        <Chip icon={SubscriptionIcon} text='Subscription Policy' />
      </ChipRowContainer>
      <TextContainer>
        <P fontSize={14} id='seamlessNewDriver-text-noFeeCancelText'>
          Cancel anytime with no fees ✅
        </P>
      </TextContainer>
    </Container>
  );
};

const SeamlessPriceDisplay: React.FC<Props> = ({
  leftActive,
  handleToggle,
  renderPromoCode,
  showPolicyDetails = false,
  renderIPTText = false,
}) => {
  const driverFN = useSelector((state: RootState) => state.quote.driver.firstName);
  const driverLN = useSelector((state: RootState) => state.quote.driver.lastName);
  const car = useSelector((state: RootState) => state.quote.car);
  const carDetails = `${car.make} ${car.model}`;
  const policyHolderName = `${driverFN} ${driverLN}`;
  const duration = useSelector((state: RootState) => state.quote.duration);
  const price = useSelector((state: RootState) => state.quote.save.price);
  const initialPrice = useSelector((state: RootState) => state.quote.save.initialPrice);
  const altProduct = useSelector((state: RootState) => state.quote.alternativeProducts);
  const altProductPrice =
    altProduct.seamless?.price !== undefined
      ? (altProduct.seamless.price as number).toFixed(2).toString()
      : undefined;
  const renderCarDetails = () => (
    <QuoteRowDetail id='quote-text-carName' label='We will insure a' value={carDetails} />
  );
  const renderPolicyHolderName = () => (
    <QuoteRowDetail
      id='quote-text-policyHoldersName'
      label='The policy holder will be'
      value={policyHolderName}
    />
  );

  return (
    <FullContainer id='seamlessPriceDisplay'>
      <SegmentedControl
        leftOption='Learner price'
        rightOption='Price after you pass'
        leftActive={leftActive}
        onClick={handleToggle}
      />
      {leftActive ? (
        <LearnerPriceView
          learnerPrice={price}
          showPolicyDetails={showPolicyDetails}
          initialPrice={initialPrice}
          renderPromoCode={renderPromoCode}
          renderIPTText={renderIPTText}
        />
      ) : (
        <PriceAfterPassView
          learnerPrice={price}
          showPolicyDetails={showPolicyDetails}
          priceAfterPass={altProductPrice}
          carDetails={carDetails}
        />
      )}
      {showPolicyDetails && (
        <QuoteDetails id='quote-component-quoteDetails' showPolicyDetails={showPolicyDetails}>
          {carDetails && renderCarDetails()}
          {carDetails && duration && leftActive && <Divider />}
          {duration && leftActive && <QuoteDurationSummary duration={duration} isSubscription />}
          {duration && policyHolderName && <Divider />}
          {policyHolderName && renderPolicyHolderName()}
        </QuoteDetails>
      )}
    </FullContainer>
  );
};

export default SeamlessPriceDisplay;

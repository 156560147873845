import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { RoundedGreyBox, IconList, Button, LinkButton } from '@rentecarlo/component-library';
import { Title } from '@organisms/modals/TextModal/styles';
import { useComponentId, ComponentType, useAppSelector, useOptimizelyFlag } from '@hooks';
import analyticsClient from '@utils/analytics';
import { saveAndGoTo } from '@redux/reducer/quote/save.actions';
import { setProduct } from '@redux/reducer/quote/product.actions';
import { RootState } from '@redux/reducer';
import SparkleIcon from '@assets/teal-sparkles.svg';
import TickIcon from '@assets/black-tick.svg';
import QuoteStep from '@templates/QuoteStep';
import SeamlessPriceDisplay from '@molecules/blocks/SeamlessPriceDisplay';
import SeamlessPassPriceModal from '@organisms/modals/SeamlessPassPriceModal';
import SeamlessFakeDoorTestModal from '@organisms/modals/SeamlessFakeDoorTestModal';
import { getAge } from '@services/date';

const TitleContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;

const Sparkles = styled.img`
  margin-left: 8px;
`;

const InfoText = styled.div`
  font-family: ${({ theme }) => theme.standard};
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  line-height: 24px;
`;

const TextHeaders = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.23px;
  color: ${({ theme }) => theme.textPrimary};
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.standard};
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.08px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 32px;
`;

const SpecialTitle: React.FC = () => {
  const creator = useComponentId();
  return (
    <TitleContainer>
      <Title id={creator(ComponentType.TEXT, 'specialTitle')}>
        We've got something special for you!
        <Sparkles src={SparkleIcon} alt='sparkle-icon' />
      </Title>
    </TitleContainer>
  );
};

const SeamlessField: React.FC = () => {
  const isSeamlessFakeDoorEnabled = useOptimizelyFlag('TEMP_ENABLE_SEAMLESS_FAKE_DOOR').enabled;
  const [showPriceAfterPass, setShowPriceAfterPass] = useState(false);
  const [showPassPriceModal, setShowPassPriceModal] = useState(false);
  const [showSeamlessFakeDoorModal, setShowSeamlessFakeDoorModal] = useState(false);
  const [openedTimestamp, setOpenedTimestamp] = useState<Dayjs | null>(null);

  const handleToggle = () => {
    setShowPriceAfterPass(!showPriceAfterPass);
    analyticsClient.trackEvent('web_seamless_product_choice_show_price_after_pass');
  };
  const openPassPriceModal = (): void => {
    const timestamp = dayjs();
    analyticsClient.trackEvent('web_productchoice_seamless_pass_price_modal_open');
    setOpenedTimestamp(timestamp);
    setShowPassPriceModal(true);
  };

  const closePassPriceModal = (): void => {
    if (openedTimestamp) {
      const durationOpen = dayjs().diff(openedTimestamp, 'second');
      analyticsClient.trackEvent('web_productchoice_seamless_pass_price_modal_closed', {
        duration_open_in_seconds: durationOpen,
      });
    }
    setShowPassPriceModal(false);
  };
  const dispatch = useDispatch();
  const creator = useComponentId();
  const quotePrice = useAppSelector((state) => state.quote.save.price);
  const isFastRepurchase = useSelector((state: RootState) => state.quote.save.isFastRepurchase);
  const productType = useSelector((state: RootState) => state.quote.product.productType);
  const altProduct = useSelector((state: RootState) => state.quote.alternativeProducts);
  const altProductPrice = (altProduct.seamless?.price as number).toFixed(2).toString();
  const isQuoteForMyself = useSelector(
    (state: RootState) => state.account.customer.isQuoteForMyself,
  );
  const driverAge = useAppSelector((state) => state.quote.driver.birthdate);

  const handleSeamlessFakeDoorTest = () => {
    setShowSeamlessFakeDoorModal(true);
  };

  const handleSeamlessOptIn = () => {
    if (isSeamlessFakeDoorEnabled) {
      handleSeamlessFakeDoorTest();
    } else {
      dispatch(setProduct('seamless'));
      if (isFastRepurchase) {
        dispatch(saveAndGoTo('final-quote', true));
      } else {
        dispatch(saveAndGoTo('important-information', true));
      }
    }
    analyticsClient.trackEvent('web_seamless_product_choice_yes', {
      seamless_pass_price: altProductPrice,
    });
    analyticsClient.trackUserProperty('driverAge', getAge(driverAge));
  };

  const handleSeamlessOptOut = () => {
    if (productType === 'seamless') {
      dispatch(setProduct('ldp'));
    }
    analyticsClient.trackEvent('web_seamless_product_choice_no', {
      seamless_pass_price: altProductPrice,
    });
    analyticsClient.trackUserProperty('driverAge', getAge(driverAge));
    if (isFastRepurchase) {
      dispatch(saveAndGoTo('final-quote'));
    } else {
      dispatch(saveAndGoTo('important-information'));
    }
  };

  const handleFakeDoorTestSubmission = () => {
    setShowSeamlessFakeDoorModal(false);
    dispatch(saveAndGoTo('important-information'));
  };

  return (
    <QuoteStep paddingTop='0' titleMarginBottom='0' id={creator(ComponentType.BUTTON, 'continue')}>
      <Container id='productChoice-component-seamlessOffer'>
        <SeamlessFakeDoorTestModal
          show={showSeamlessFakeDoorModal}
          close={() => setShowSeamlessFakeDoorModal(false)}
          fakeDoorTestSubmission={handleFakeDoorTestSubmission}
        />
        <SpecialTitle />
        <RoundedGreyBox padding={[16, 16, 16, 16]}>
          <InfoText id='productChoice-text-seamlessInfo'>
            Want to hit the road right after passing your driving test and unlock exclusive
            discounts? Try <b>Learner + New Driver</b>, our revolutionary new way of doing
            insurance!
          </InfoText>
        </RoundedGreyBox>
        <IconList
          id='productChoice-component-seamlessInfoList'
          icon={TickIcon}
          items={[
            <Text id='productChoice-text-seamlessInfoListOne'>
              <TextHeaders>All-in-one cover</TextHeaders>
              Stay covered as a learner and after you pass—same policy, hassle-free!
            </Text>,
            <Text id='productChoice-text-seamlessInfoListOne'>
              <TextHeaders>Save big after you pass 🎉</TextHeaders>
              Your first month post-test is at your learner rate!
            </Text>,
            <Text id='productChoice-text-seamlessInfoListTwo'>
              <TextHeaders>Drive safe, save more 🚗</TextHeaders>
              After passing your test, use our telematics app to track your driving and earn
              discounts as your driving improves. <b>No black box!</b>
            </Text>,
            <Text id='productChoice-text-seamlessInfoListThree'>
              <TextHeaders>No strings attached </TextHeaders>
              Cancel anytime—before or after passing—with no hidden fees.
            </Text>,
          ]}
        />
        <SeamlessPriceDisplay
          leftActive={!showPriceAfterPass}
          handleToggle={handleToggle}
          learnerPrice={quotePrice}
          priceAfterPass={altProductPrice}
        />
        {showPriceAfterPass && (
          <>
            <LinkButton
              id='productChoice-button-whySeamlessPriceGoesUp'
              action={() => openPassPriceModal()}
              bold
            >
              Why does my price go up?
            </LinkButton>
            <SeamlessPassPriceModal
              show={showPassPriceModal}
              close={() => closePassPriceModal()}
              isQuoteForMyself={isQuoteForMyself}
            />
          </>
        )}
      </Container>
      <ButtonContainer>
        <Button
          id={creator(ComponentType.BUTTON, 'seamlessOfferContinueWithSeamless')}
          onClick={() => handleSeamlessOptIn()}
        >
          Continue with Learner + New Driver
        </Button>
        <Button
          id={creator(ComponentType.BUTTON, 'seamlessOfferNoThanks')}
          onClick={() => handleSeamlessOptOut()}
          backgroundColor='transparent'
          borderColor='transparent'
          textColor='#171717'
          textHoverColor='#fff'
        >
          No thanks
        </Button>
      </ButtonContainer>
    </QuoteStep>
  );
};

export default SeamlessField;

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(duration);
dayjs.extend(isBetween);
/*
 * Provides options for the indicative price quick selector.
 * WARNING: When adding options ensure the backend has been updates
 * to return this hours duration. (pricing_transformer.py)
 */
const ONE_DAY_IN_HOURS = 24;
const ONE_WEEK_IN_HOURS = ONE_DAY_IN_HOURS * 7;
const ONE_MONTH_IN_HOURS = ONE_DAY_IN_HOURS * 30;

export interface DurationOptions {
  text: string;
  value: string;
  inHours: number;
  sub: string;
  validate: boolean;
}
export interface BaseDurationConfig {
  default: {
    value: string;
    tab: string;
    inHours: number;
  };
  hours: {
    text: 'HOURS';
    value: 'hours';
    locked: boolean;
    options: DurationOptions[];
  };
  days: {
    text: 'DAYS';
    value: 'days';
    locked: boolean;
    options: DurationOptions[];
  };
  weeks: {
    text: 'WEEKS';
    value: 'weeks';
    locked: boolean;
    options: DurationOptions[];
  };
}

export interface DurationConfigWithMonths extends BaseDurationConfig {
  months: {
    text: 'MONTHS';
    value: 'months';
    locked: boolean;
    options: DurationOptions[];
  };
}

export interface DurationConfigSubs {
  default: {
    value: string;
    tab: string;
    inHours: number;
  };
  months: {
    text: 'MONTHS';
    value: 'months';
    locked: boolean;
    options: DurationOptions[];
  };
}

export type Durations = BaseDurationConfig | DurationConfigWithMonths;

const csiConfig: BaseDurationConfig = {
  default: {
    value: '3-day',
    tab: 'days',
    inHours: ONE_DAY_IN_HOURS * 3,
  },
  hours: {
    text: 'HOURS',
    value: 'hours',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-hour',
        inHours: 1,
        sub: 'HOUR',
        validate: dayjs().isBetween(
          dayjs().hour(5).minute(0).second(0),
          dayjs().hour(20).minute(0).second(0),
          null,
          '[]',
        ),
      },
      {
        text: '2',
        value: '2-hour',
        inHours: 2,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(14).minute(0).second(0),
          dayjs().hour(20).minute(0).second(0),
          null,
          '(]',
        ),
      },
      {
        text: '4',
        value: '4-hour',
        inHours: 4,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(5).minute(0).second(0),
          dayjs().hour(18).minute(0).second(0),
          null,
          '[)',
        ),
      },
      {
        text: '6',
        value: '6-hour',
        inHours: 6,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(5).minute(0).second(0),
          dayjs().hour(16).minute(0).second(0),
          null,
          '[)',
        ),
      },
      {
        text: '8',
        value: '8-hour',
        inHours: 8,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(5).minute(0).second(0),
          dayjs().hour(14).minute(0).second(0),
          null,
          '[)',
        ),
      },
      {
        text: '12',
        value: '12-hour',
        inHours: 12,
        sub: 'HOURS',
        validate:
          dayjs().isBetween(
            dayjs().hour(5).minute(0).second(0),
            dayjs().hour(10).minute(0).second(0),
            null,
            '[)',
          ) ||
          dayjs().isBetween(
            dayjs().hour(17).minute(0).second(0),
            dayjs().hour(20).minute(0).second(0),
            null,
            '[)',
          ),
      },
      {
        text: '24',
        value: '24-hour',
        inHours: 24,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(10).minute(0).second(0),
          dayjs().hour(20).minute(0).second(0),
          null,
          '[)',
        ),
      },
    ],
  },
  days: {
    text: 'DAYS',
    value: 'days',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-day',
        inHours: 24,
        sub: 'DAY',
        validate: true,
      },
      {
        text: '1.5',
        value: '1.5-day',
        inHours: 36,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '2',
        value: '2-day',
        inHours: 48,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '3',
        value: '3-day',
        inHours: 72,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '5',
        value: '5-day',
        inHours: 120,
        sub: 'DAYS',
        validate: true,
      },
    ],
  },
  weeks: {
    text: 'WEEKS',
    value: 'weeks',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-week',
        inHours: 168,
        sub: 'WEEK',
        validate: true,
      },
      {
        text: '2',
        value: '2-week',
        inHours: 336,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '3',
        value: '3-week',
        inHours: 504,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '30',
        value: '30-days',
        inHours: 720,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '60',
        value: '60-days',
        inHours: 1440,
        sub: 'DAYS',
        validate: true,
      },
    ],
  },
};

const ldpConfig: DurationConfigWithMonths = {
  default: {
    value: '7-day',
    tab: 'days',
    inHours: ONE_DAY_IN_HOURS * 7,
  },
  hours: {
    text: 'HOURS',
    value: 'hours',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-hour',
        inHours: 1,
        sub: 'HOUR',
        validate: dayjs().isBetween(
          dayjs().hour(5).minute(0).second(0),
          dayjs().hour(20).minute(0).second(0),
          null,
          '[]',
        ),
      },
      {
        text: '2',
        value: '2-hour',
        inHours: 2,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(14).minute(0).second(0),
          dayjs().hour(20).minute(0).second(0),
          null,
          '(]',
        ),
      },
      {
        text: '4',
        value: '4-hour',
        inHours: 4,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(5).minute(0).second(0),
          dayjs().hour(18).minute(0).second(0),
          null,
          '[)',
        ),
      },
      {
        text: '6',
        value: '6-hour',
        inHours: 6,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(5).minute(0).second(0),
          dayjs().hour(16).minute(0).second(0),
          null,
          '[)',
        ),
      },
      {
        text: '8',
        value: '8-hour',
        inHours: 8,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(5).minute(0).second(0),
          dayjs().hour(14).minute(0).second(0),
          null,
          '[)',
        ),
      },
      {
        text: '12',
        value: '12-hour',
        inHours: 12,
        sub: 'HOURS',
        validate:
          dayjs().isBetween(
            dayjs().hour(5).minute(0).second(0),
            dayjs().hour(10).minute(0).second(0),
            null,
            '[)',
          ) ||
          dayjs().isBetween(
            dayjs().hour(17).minute(0).second(0),
            dayjs().hour(20).minute(0).second(0),
            null,
            '[)',
          ),
      },
      {
        text: '24',
        value: '24-hour',
        inHours: 24,
        sub: 'HOURS',
        validate: dayjs().isBetween(
          dayjs().hour(10).minute(0).second(0),
          dayjs().hour(20).minute(0).second(0),
          null,
          '[)',
        ),
      },
    ],
  },
  days: {
    text: 'DAYS',
    value: 'days',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-day',
        inHours: 24,
        sub: 'DAY',
        validate: true,
      },
      {
        text: '2',
        value: '2-day',
        inHours: 48,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '3',
        value: '3-day',
        inHours: 72,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '5',
        value: '5-day',
        inHours: 120,
        sub: 'DAYS',
        validate: true,
      },
      {
        text: '7',
        value: '7-day',
        inHours: 168,
        sub: 'DAYS',
        validate: true,
      },
    ],
  },
  weeks: {
    text: 'WEEKS',
    value: 'weeks',
    locked: false,
    options: [
      {
        text: '2',
        value: '2-week',
        inHours: ONE_WEEK_IN_HOURS * 2,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '4',
        value: '4-week',
        inHours: ONE_WEEK_IN_HOURS * 4,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '6',
        value: '6-week',
        inHours: ONE_WEEK_IN_HOURS * 6,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '8',
        value: '8-week',
        inHours: ONE_WEEK_IN_HOURS * 8,
        sub: 'WEEKS',
        validate: true,
      },
      {
        text: '12',
        value: '12-week',
        inHours: ONE_WEEK_IN_HOURS * 12,
        sub: 'WEEKS',
        validate: true,
      },
    ],
  },
  months: {
    text: 'MONTHS',
    value: 'months',
    locked: false,
    options: [
      {
        text: '1',
        value: '1-month',
        inHours: ONE_MONTH_IN_HOURS,
        sub: 'MONTH',
        validate: true,
      },
      {
        text: '2',
        value: '2-month',
        inHours: ONE_MONTH_IN_HOURS * 2,
        sub: 'MONTHS',
        validate: true,
      },
      {
        text: '3',
        value: '3-month',
        inHours: ONE_MONTH_IN_HOURS * 3,
        sub: 'MONTHS',
        validate: true,
      },
      {
        text: '4',
        value: '4-month',
        inHours: ONE_MONTH_IN_HOURS * 4,
        sub: 'MONTHS',
        validate: true,
      },
      {
        text: '6',
        value: '6-month',
        inHours: ONE_MONTH_IN_HOURS * 6,
        sub: 'MONTHS',
        validate: true,
      },
    ],
  },
};

const subsConfig = (): DurationConfigSubs => {
  // This is a holdover from the first version of subscriptions.
  // We still use it set the duration end on the quote when updating,
  // but the backend has the final say on when the subscription policy actually ends.
  const subsDuration = 720; // 30 days in hours

  return {
    default: {
      value: '1-month',
      tab: 'months',
      inHours: subsDuration,
    },
    months: {
      text: 'MONTHS',
      value: 'months',
      locked: false,
      options: [
        {
          text: '1',
          value: '1-month',
          inHours: subsDuration,
          sub: 'MONTH',
          validate: true,
        },
      ],
    },
  };
};


export default {
  csi: csiConfig,
  ldp: ldpConfig,
  getSubs: subsConfig,
};

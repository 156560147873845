import { v4 as uuidv4 } from 'uuid';
import { all, call, delay, put, select } from 'redux-saga/effects';

import deleteOldCsrf from '@services/api/csrf';
import { setSessionCookie, getCookie } from '@services/cookies';

import { setUserField } from '@reducer/quote/user.actions';
import {
  fetchRestrictedPeriodsSuccess,
  fetchRestrictedPeriodsFailure,
  amplifyLoaded,
  setOptimiselyAttribute,
} from '@reducer/config/actions';
import * as AmplifyService from '@services/amplify';
import convertProductType from '@services/convertProductType';
import getAffiliate from '@services/getAffiliateTag';
import { optimizelyClient } from '@services/clients/optimizely';
import { initGA } from '@services/ecommerceTracking';
import * as Sentry from '@sentry/react';
import store from '@redux/store';
import handleError from '@redux/utils/errorHandler';
import analyticsClient from '@utils/analytics';
import { initStore, checkSession } from '../init';
import { restrictedPeriodsApi } from '../../services/api';

export function* retrieveRestrictedPeriods() {
  try {
    const response = yield call(restrictedPeriodsApi.get);
    yield put(fetchRestrictedPeriodsSuccess(response));
  } catch (error) {
    handleError(error);
    yield put(fetchRestrictedPeriodsFailure(error));
  }
}

function* initialiseAmplifyService() {
  try {
    yield call(() => AmplifyService.initialise());
    yield put(amplifyLoaded());
  } catch (error) {
    handleError(error);
  }
}

function* deleteCsrf() {
  try {
    yield call(deleteOldCsrf);
  } catch (error) {
    handleError(error);
  }
}

function* setUserIdentifier() {
  let userIdentity = getCookie('user_identity');

  if (userIdentity === undefined) {
    userIdentity = uuidv4();

    const cookieYesCookie = getCookie('cookieyes-consent');
    if (cookieYesCookie && cookieYesCookie.includes('functional:yes')) {
      setSessionCookie('user_identity', userIdentity);
    }
  }
  yield put(setOptimiselyAttribute('user_identity', userIdentity));
}

export function initialiseAnalytics() {
  const cookieYesCookie = getCookie('cookieyes-consent');

  const amplitudeEnabled = Boolean(cookieYesCookie) && cookieYesCookie.includes('analytics:yes');

  analyticsClient
    .init({
      amplitude: { enabled: amplitudeEnabled, apiKey: process.env.REACT_APP_AMPLITUDE_ID },
    })
    .then(() => {
      // not necessary to do anything once it resolves but the difficulty of awaiting async in redux requires this
    });

  analyticsClient.addGlobalProperties(() => {
    const state = store.getState();
    const { productType, isSubscription } = state.quote.product;
    const { isDriverOwner } = state.quote.driver;
    const { uuid } = state.quote.save;
    const product = convertProductType(productType, isDriverOwner).toUpperCase();

    const { loginPoint } = state.quote.legal;
    const { loggedIn } = state.account.login;

    return {
      product_type: product,
      is_subscription: isSubscription,
      is_logged_in: loggedIn,
      login_point: loginPoint,
      quote_uuid: uuid,
    };
  });
}

export function* runInit() {
  initGA();
  yield delay(100);
  yield deleteCsrf();

  yield put(initStore());

  const affiliateId = getAffiliate();
  if (affiliateId) {
    yield put(setUserField('affiliateId', affiliateId));
  }
  yield retrieveRestrictedPeriods();
  yield setUserIdentifier();
  yield initialiseAmplifyService();

  yield put(checkSession());
}

export function* initAnalytics() {
  yield call(initialiseAnalytics);
}

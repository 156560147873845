import styled from 'styled-components';

export const NavBarContainer = styled.div`
  width: 100%;
  min-height: 60px;
  *:focus {
    outline: none;
  }
  position: sticky;
  z-index: 2;
`;

/* eslint-disable @typescript-eslint/ban-types */
import React, { ReactNode } from 'react';
import { Field, change, getFormSyncErrors } from 'redux-form';

import Group from '@atoms/layout/form/Group';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import CarRegistrationSearch from '@atoms/inputs/CarRegistrationSearch';
import ReduxFieldValidation from '@services/formValidation';
import SelectField from '@molecules/selects/SelectField';
import carValues from '@config/carValues';
import VehicleLookupForm from '@atoms/inputs/VehicleLookup';
import { useAppDispatch, useAppSelector } from '@hooks';

import { ErrorIcon, FieldContainer, TextLink } from '@rentecarlo/component-library';
import styled from 'styled-components';
import { lookupCar, resetLookupCar } from '@reducer/lookup/actions';
import { saveQuoteAndLookupCar } from '@reducer/quote/save.actions';
import { CarValueWrapper, List } from './assets/styles';
import CarDetailsCard from '../CarDetailsCard';

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  isVehicleLookup?: boolean;
  skipValidation?: boolean;
  submitFailed: boolean;
  isQuoteForMyself: boolean;
  handleCarSubmit?: (func: (arg0: string) => void) => void;
  saveQuote?: boolean;
  quoteStep?: string;
}

const ErrorImage = styled.img``;

const ErrorMessage = styled.span`
  margin-left: 1rem;
  font-weight: bold;
`;

export const handleErrorCode = (errorCode: string, errorMessage: string): ReactNode => {
  switch (errorCode) {
    case 'VEHICLE_TOO_OLD':
      return (
        <span id='quote-text-carSearchVehicleTooOld'>
          {`Unfortunately, we are unable to insure your vehicle because it belongs in the category
          listed below:`}
          <br />
          <br />
          <ErrorContainer>
            <ErrorImage src={ErrorIcon} />
            <ErrorMessage id='quote-text-carSearchVehicleTooOldError'>
              Classic Car (Over 40 years old)
            </ErrorMessage>
          </ErrorContainer>
        </span>
      );

    case 'VEHICLE_IS_IMPORTED':
      return (
        <span id='quote-text-carSearchVehicleImported'>
          Unfortunately, we are unable to insure your vehicle because it belongs in the category
          listed below:
          <br />
          <br />
          <ErrorContainer>
            <ErrorImage src={ErrorIcon} />
            <ErrorMessage id='quote-text-carSearchVehicleImportedError'>
              Imported Vehicle
            </ErrorMessage>
          </ErrorContainer>
        </span>
      );

    case 'CARWEB_MISSING_DATA':
      return (
        <span id='quote-text-carSearchVehicleMissingData'>
          We're having trouble finding your car. Drop us a line at{' '}
          <TextLink
            id='quote-link-carSearchVehicleMissingDataContact'
            href='https://www.veygo.com/contact/'
          >
            https://www.veygo.com/contact/
          </TextLink>{' '}
          and we'll give you a hand
        </span>
      );
    case 'CARWEB_ERROR_1000':
      return (
        <span id='quote-text-carSearchVehicleDefault'>
          Unfortunately, if your vehicle is one of the following, we can’t provide cover:
          <br />
          <br />
          <ErrorContainer>
            <ErrorImage src={ErrorIcon} />
            <List>
              <li id='quote-text-carSearchVehicleDefaultListOne'>Imported vehicle</li>
              <li id='quote-text-carSearchVehicleDefaultListTwo'>Camper van</li>
              <li id='quote-text-carSearchVehicleDefaultListThree'>Vehicle is over 40 years old</li>
            </List>
          </ErrorContainer>
          <br />
          And if not, then make sure you’ve entered the registration correctly - be sure to check
          that any 0 (number) is not O (letter) or similar.
          <br />
          <br />
          Another problem could be if you’ve recently changed the vehicle's registration plate, this
          can take up to a month for us to receive this information from the DVLA.
        </span>
      );
    case 'VEHICLE_NOT_ALLOWED':
      return (
        <span id='quote-text-carSearchVehicleNotAllowed'>
          Unfortunately, we can't insure you on this vehicle. If you want to try another one, just
          enter a different registration and press 'Lookup car' again.
        </span>
      );
    default:
      return <span id='quote-text-carSearchVehicleError'>{errorMessage}</span>;
  }
};

const CarSearch: React.FC<Props> = ({
  skipValidation = false,
  submitFailed,
  isVehicleLookup = false,
  isQuoteForMyself = true,
  handleCarSubmit,
  saveQuote = false,
  quoteStep,
}) => {
  const isQuoteStart = window.location.pathname === '/quote-start';
  const dispatch = useAppDispatch();

  const car = useAppSelector((state) => state.lookup.car);
  const errorMessage = useAppSelector((state) => state.lookup.errors?.carReg);
  const errorCode = useAppSelector((state) => state.lookup.errors?.errorCode);
  const loading = useAppSelector((state) => state.lookup.loading);
  const registrationNumber = useAppSelector((state) => state.lookup.registrationNumber);
  const indicativePriceMissing = useAppSelector((state) => state.lookup.indicativePriceMissing);
  const formErrors = useAppSelector((state) => getFormSyncErrors('carForm')(state));
  const formQuoteStartErrors = useAppSelector((state) =>
    getFormSyncErrors('quoteStartForm')(state),
  );
  const vehicleRegistration = useAppSelector(
    (state) => state?.form?.quoteStartForm?.values?.vehicleRegistration,
  );

  const carLookup = (carReg: string): void => {
    dispatch(saveQuote ? saveQuoteAndLookupCar(carReg) : lookupCar(carReg));
    dispatch(change('carForm', 'termsOfCoverConfirmation', false));
    dispatch(change('carForm', 'hasInsurance', null));
    dispatch(change('carForm', 'personalTempcoverVehicleConfirmed', false));
    dispatch(change('carForm', 'tempcoverReason', ''));
    dispatch(change('carForm', 'mainInsurer', ''));
    dispatch(change('carForm', 'alternateInsurer', ''));
  };

  const resetCarLookup = () => dispatch(resetLookupCar());

  const submitDecorator = (func: (arg0: string) => void) => {
    if (typeof handleCarSubmit === 'function') {
      return handleCarSubmit(func);
    }
    return func;
  };

  return (
    <Group id='quote-container-carSearch'>
      <SectionTitle id='quote-title-carSearchRegistration'>
        {`What is the registration for the car ${isQuoteForMyself ? 'you' : 'they'} want to drive?`}
      </SectionTitle>
      {!isVehicleLookup && (
        <FieldContainer
          id='quote-container-carRegistration'
          showErrorContainer={
            (submitFailed && formErrors && formErrors.carRegistration) || errorMessage
          }
          showError={(submitFailed && formErrors && formErrors.carRegistration) || errorMessage}
          error={
            (formErrors && formErrors.carRegistration) || handleErrorCode(errorCode, errorMessage)
          }
        >
          <Field
            id='quote-field-carRegistration'
            name='carRegistration'
            component={CarRegistrationSearch}
            props={{
              onSearch: (reg: string): void => carLookup(reg),
            }}
            validate={skipValidation ? [] : [ReduxFieldValidation.isValidCarReg]}
          />
        </FieldContainer>
      )}
      {isVehicleLookup && (
        <VehicleLookupForm
          lookupCar={submitDecorator(carLookup)}
          car={car}
          loading={loading}
          formErrors={formErrors}
          submitFailed={submitFailed}
          handleErrorCode={handleErrorCode}
          errorMessage={errorMessage}
          skipValidation={skipValidation}
          errorCode={errorCode || undefined}
          resetLookupCar={resetCarLookup}
          formQuoteStartErrors={formQuoteStartErrors}
          vehicleRegistration={vehicleRegistration}
          disableSubmit={!handleCarSubmit}
          quoteStep={quoteStep}
        />
      )}
      {car && !errorMessage && (
        <>
          <CarDetailsCard car={car} registrationNumber={registrationNumber} />
          {indicativePriceMissing && !isQuoteStart && (
            <CarValueWrapper>
              <SectionTitle id='quote-title-carSearchCarValue'>
                How much is this car worth, roughly?
              </SectionTitle>
              <Field
                id='quote-field-carSearchValueSelect'
                name='carValue'
                component={SelectField}
                options={carValues}
                placeholder='Select a value'
                validate={skipValidation ? [] : [ReduxFieldValidation.required]}
                desktopRightMargin='-43px'
              />
            </CarValueWrapper>
          )}
        </>
      )}
    </Group>
  );
};

export default CarSearch;

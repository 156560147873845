import styled from 'styled-components';
import colors from '@config/colors';

export interface StyledInputProps {
  placeholder?: string;
  value?: string;
  isOpen?: boolean;
  isSelected?: boolean;
  isVisible?: boolean;
  borderBottomColor?: string;
  visibleItemCount?: number;
}

interface StyledUlProps {
  isOpen: boolean;
  visibleItemCount: number;
}

const ITEM_HEIGHT = 64;

export const StyledSelector = styled.div`
  background-color: white;
  border: 0.5px solid ${colors.grey.lines.light};
  border-bottom: 2px solid ${({ borderBottomColor }): string => borderBottomColor};
  border-radius: ${({ isOpen }: StyledInputProps): string => (isOpen ? '4px 4px 0px 0px' : '4px')};
  color: ${({ value }: StyledInputProps): string =>
    value ? colors.charcoalGrey : colors.grey.text.light};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: proxima-soft;
  font-size: 16px;
  font-weight: ${({ value }: StyledInputProps): string => (value ? 'bold' : 'normal')};
  min-height: ${ITEM_HEIGHT}px;
  line-height: 16px;
  padding: 24px 24px 24px 16px;
`;

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledLeftWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const StyledRightWrapper = styled.div`
  display: flex;
`;

export const StyledSelectorImg = styled.img`
  transform: ${({ isOpen }: StyledInputProps): string =>
    isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  height: 12px;
  width: auto;
`;

export const StyledOptionImg = styled.img`
  display: ${({ isVisible }: StyledInputProps): string => (isVisible ? 'block' : 'none')};
  height: 20px;
  width: 20px;
`;

export const StyledUl = styled.ul<StyledUlProps>`
  background-color: white;
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: 0px 0px 4px 4px;
  border-top: ${({ isOpen }): string =>
    isOpen ? 'none' : `0.5px solid ${colors.grey.lines.light}`};
  display: ${({ isOpen }): string => (isOpen ? 'block' : 'none')};
  left: 0;
  list-style: none;
  max-height: ${({ visibleItemCount }): number =>
    visibleItemCount * ITEM_HEIGHT + ITEM_HEIGHT / 2}px;

  overflow: auto;
  padding: 0px;
  position: absolute;
  right: 0;
  z-index: 1;
`;

export const StyledLi = styled.li`
  align-items: center;
  background-color: ${({ isSelected }: StyledInputProps): string =>
    isSelected ? colors.green.light : 'none'};
  border-bottom: 0.5px solid ${colors.grey.lines.light};
  cursor: pointer;
  display: flex;
  font-family: proxima-soft;
  font-size: 16px;
  height: ${ITEM_HEIGHT}px;
  line-height: 16px;
  padding: 0px 20px 0px 16px;
  width: 100%;

  &:hover {
    background-color: ${colors.grey.background.hover};
  }
`;

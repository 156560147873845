import { createInstance, OptimizelyDecideOption } from '@optimizely/react-sdk';
import {
  getOptimizelyUserIdentity,
  optimizelyOverrideAttributes,
} from '@redux/reselectors/optimizely';
import analyticsClient from '@utils/analytics';
import { select } from 'redux-saga/effects';

export const optimizelyClient = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
  logLevel: 'warn',
});

const originalIsFeatureEnabled = optimizelyClient.isFeatureEnabled.bind(optimizelyClient);
optimizelyClient.isFeatureEnabled = (featureKey, userId, attributes) => {
  const isEnabled = originalIsFeatureEnabled(featureKey, userId, attributes);

  analyticsClient.trackFeatureFlagDecision(featureKey, isEnabled);
  return isEnabled;
};

export function* trackEvent(eventName, values = {}) {
  const userId = yield select(getOptimizelyUserIdentity);
  const attributes = yield select(optimizelyOverrideAttributes);

  optimizelyClient.track(eventName, userId, attributes, values);
}

import React from 'react';

const getReasonsForTempcover = (changeToCsi, isQuoteForMyself) => {
  if (isQuoteForMyself) {
    return [
      {
        title: 'I am buying a car',
        description:
          'You have purchased a car, and are the new registered keeper. You need driveaway cover before buying main annual insurance.',
        value: 'tc_buying',
        id: 'buyingCarSelector',
      },
      {
        title: 'I am selling my car',
        description:
          "You are selling your car in the near future and don't want to purchase an annual insurance policy. You must be the registered keeper of the car and not doing this for business or trade purposes.",
        value: 'tc_selling',
        id: 'sellingCarSelector',
      },
      {
        title: 'I need short-term cover for personal / social use',
        description:
          "You're visiting family, helping a mate move house, home from Uni, going on a weekend trip to Cornwall, shopping, taking your car (or your cat) for an MOT / check-up...",
        value: 'tc_personal',
        id: 'personalSelector',
      },
      {
        title: 'I am borrowing a car that I own from the person that usually drives it',
        description: (
          <span>
            You're the registered keeper of the car but you aren't the regular user. However, you
            need to drive the car for a short time. If you're borrowing a car from someone but you
            aren't the registered keeper (this includes borrowing from your partner or family
            member), please <a onClick={changeToCsi}>click here</a>.
          </span>
        ),
        value: 'tc_borrowing',
        id: 'borrowingSelector',
      },
      {
        title: 'I need temporary cover until I get yearly insurance',
        description:
          "You're shopping around for a new yearly insurance policy and need temporary cover with us until you find the right price or insurer.",
        value: 'tc_shopforannual',
        id: 'shopForAnnualSelector',
      },
      {
        title: 'I need to release a car that has been impounded by the police',
        description: '',
        value: 'tc_impound',
        id: 'colleagueSelector',
      },
      {
        title: 'Other',
        description: "I can't find a reason that fits.",
        value: 'tc_other',
        id: 'otherFamilySelector',
      },
    ];
  }
  return [
    {
      title: 'They are buying a car',
      description:
        'They have purchased a car, and are the new registered keeper. They need driveaway cover before buying main annual insurance.',
      value: 'tc_buying',
      id: 'buyingCarSelector',
    },
    {
      title: 'They are selling their car',
      description:
        "They are selling their car in the near future and don't want to purchase an annual insurance policy. They must be the registered keeper of the car and not doing this for business or trade purposes.",
      value: 'tc_selling',
      id: 'sellingCarSelector',
    },
    {
      title: 'They need short-term cover for personal / social use',
      description:
        "They're visiting family, helping a mate move house, home from Uni, going on a weekend trip to Cornwall, shopping, taking their car (or their cat) for an MOT / check-up...",
      value: 'tc_personal',
      id: 'personalSelector',
    },
    {
      title: 'They are borrowing a car that they own from the person that usually drives it',
      description: (
        <span>
          They're the registered keeper of the car but they aren't the regular user. However, they
          need to drive the car for a short time. If they're borrowing a car from someone but they
          aren't the registered keeper (this includes borrowing from their partner or family
          member), please <a onClick={changeToCsi}>click here</a>.
        </span>
      ),
      value: 'tc_borrowing',
      id: 'borrowingSelector',
    },
    {
      title: 'They need temporary cover until they get yearly insurance',
      description:
        "They're shopping around for a new yearly insurance policy and need temporary cover with us until you find the right price or insurer.",
      value: 'tc_shopforannual',
      id: 'shopForAnnualSelector',
    },
    {
      title: 'They need to release a car that has been impounded by the police',
      description: '',
      value: 'tc_impound',
      id: 'colleagueSelector',
    },
    {
      title: 'Other',
      description: "They can't find a reason that fits.",
      value: 'tc_other',
      id: 'otherFamilySelector',
    },
  ];
};

export default getReasonsForTempcover;

export const getReasonForCoverDropdownValues = (isQuoteForMyself) => {
  if (!isQuoteForMyself) {
    return [
      { name: 'They need cover for personal use', value: 'tc_personal' },
      { name: 'They are buying a car', value: 'tc_buying' },
      { name: 'They are selling their car', value: 'tc_selling' },
      { name: 'They are shopping for an annual policy', value: 'tc_shopforannual' },
      { name: 'They are borrowing their own car from its usual driver', value: 'tc_borrowing' },
      { name: 'Releasing a car from impound', value: 'tc_impound' },
      { name: "I can't find a reason", value: 'tc_other' },
    ];
  }

  return [
    { name: 'I need cover for personal use', value: 'tc_personal' },
    { name: 'I am buying a car', value: 'tc_buying' },
    { name: 'I am selling my car', value: 'tc_selling' },
    { name: 'I am shopping for an annual policy', value: 'tc_shopforannual' },
    { name: 'I am borrowing my own car from its usual driver', value: 'tc_borrowing' },
    { name: 'Releasing a car from impound', value: 'tc_impound' },
    { name: "I can't find a reason", value: 'tc_other' },
  ];
};

import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import DropDownField from '@atoms/inputs/DropDownField';
import ReduxFieldValidation from '@services/formValidation';
import QuoteStep from '@templates/QuoteStep';
import { ComponentType, useComponentId, useOptimizelyFlag } from '@hooks';
import driverdetailsIcon from '@assets/manual.svg';
import carIcon from '@assets/car-front.svg';
import receiptIcon from '@assets/receipt.svg';
import certificateIcon from '@assets/certificate.svg';
import { InformationBox, FieldContainer } from '@rentecarlo/component-library';

import overnightLocationOptions from '@config/overnightLocationOptions';
import FastRepurchaseSummary from './FastRepurchaseSummary';
import { ComponentProps } from './ConfirmFastRepurchaseDetails.container';

const ConfirmFastRepurchaseDetails: React.FC<ComponentProps & InjectedFormProps> = (props) => {
  const creator = useComponentId();
  const {
    driverDetailsData,
    vehicleInformationData,
    payableExcessData,
    drivingHistoryData,
    underwriting,
    driverDetailsEdit,
    vehicleInformationEdit,
    payableExcessEdit,
    drivingHistoryEdit,
    submission,
    isSubscription,
    productType,
    handleSubmit,
  } = props;

  const canEditDriver = true;
  const isSeamlessEnabled = useOptimizelyFlag('TEMP_ENABLE_SEAMLESS').enabled;

  return (
    <QuoteStep
      id={creator(ComponentType.COMPONENT, 'quoteStep')}
      includeStepPrefix={false}
      nextAction={handleSubmit((values: Record<string, string>) => {
        submission(values);
      })}
      nextLabel='Confirm'
      stepTitle='Confirm details'
      stepSubtitle='Please check these details are correct'
      form
      formErrors={{}}
    >
      <FastRepurchaseSummary
        title='Driver details'
        pillButtonAction={driverDetailsEdit}
        hidePillButton={!canEditDriver}
        icon={driverdetailsIcon}
        data={driverDetailsData}
      />
      <FastRepurchaseSummary
        title='Vehicle information'
        pillButtonAction={vehicleInformationEdit}
        hidePillButton={false}
        icon={carIcon}
        data={vehicleInformationData}
      />
      <FastRepurchaseSummary
        title='Driving history'
        pillButtonAction={drivingHistoryEdit}
        hidePillButton={false}
        icon={certificateIcon}
        data={drivingHistoryData}
      />
      <FastRepurchaseSummary
        title='Payable excess'
        pillButtonAction={payableExcessEdit}
        hidePillButton={!underwriting}
        icon={receiptIcon}
        data={payableExcessData}
      >
        <InformationBox
          id={creator(ComponentType.FIELD, 'mobileImportantInformation')}
          type='important'
        >
          The excess is the amount you pay in the event of any claim on this policy, regardless of
          who is responsible for the incident.
        </InformationBox>
      </FastRepurchaseSummary>
      {isSeamlessEnabled &&
        (productType === 'ldp' || productType === 'seamless') &&
        isSubscription && (
          <FieldContainer>
            <Field
              name='overnightLocation'
              label='Where will the car be parked overnight?'
              id='confirmdetails-field-overnightLocationDropdown'
              component={DropDownField}
              placeholder='Select where the car will be parked'
              options={overnightLocationOptions}
              errorText='Overnight location is required.'
              validate={[ReduxFieldValidation.isRequired]}
            />
          </FieldContainer>
        )}
    </QuoteStep>
  );
};

export default reduxForm({
  form: 'confirmFastRepurchaseDetailsForm',
})(ConfirmFastRepurchaseDetails);

import { buildDateForApi } from '@services/date';
import HttpClient from './httpClient';

import { customer as customerTransformer, addresses as addressTransformer } from '../transformers';

const customer = {
  retrieveCustomerAdditionalData() {
    return new HttpClient.Builder().get('/accnt/customers/customer-additional-data/').execute();
  },

  checkEmail(email) {
    return new HttpClient.Builder().post(`/accnt/login/check_email`).data({ email }).execute();
  },
};

export default customer;

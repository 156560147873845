/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, WrappedFieldProps } from 'redux-form';

import ReduxFieldValidation from '@services/formValidation';
import ToggleButtonsOwner from '@molecules/inputs/ToggleButtonsOwner';
import { FieldContainer } from '@rentecarlo/component-library';

import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import SectionSubTitle from '@atoms/titles/componentTitles/SectionSubTitle';
import Group from '@atoms/layout/form/Group';

import CarSearch from '@organisms/blocks/CarSearch';
import { RootState } from '@redux/reducer';

type Props = {
  setProduct: Function;
  toggleStatus: Function;
  setDriverOwner: Function;
  submitFailed: boolean;
  licenceType: string;
  isQuoteForMyself: boolean;
  handleCarSubmit: (func: (arg0: string) => void) => void;
  quoteStep?: string;
};

type OwnerOrDriverSelectorProps = {
  setProduct: Function;
  setDriverOwner: Function;
  id: string;
  disabled: boolean;
  isFullLicenceType: boolean;
  isQuoteForMyself: boolean;
};

type OwnerDriverSuscriptionProps = {
  id: string;
  type: string;
};

const ownerDriverSuscriptionMapper: Array<OwnerDriverSuscriptionProps> = [
  {
    id: 'owner',
    type: 'owner',
  },
  {
    id: 'driver',
    type: 'driver',
  },
];

const OwnerOrDriverSelector: React.FC<OwnerOrDriverSelectorProps & WrappedFieldProps> = ({
  input: { value, onChange },
  setProduct,
  setDriverOwner,
  meta: { warning, error, submitFailed },
  id,
  disabled,
  isQuoteForMyself,
}) => (
  <FieldContainer
    id={id}
    showErrorContainer={submitFailed && error}
    showError={!!((submitFailed || value) && error)}
    error={error}
  >
    <ToggleButtonsOwner
      value={value}
      onSelect={(selectedValue: string): void => {
        const ownerDriverSubsObject = ownerDriverSuscriptionMapper.find(
          (mapper: OwnerDriverSuscriptionProps) => mapper.id === selectedValue,
        );
        onChange(selectedValue);
        setProduct(ownerDriverSubsObject?.type);
        setDriverOwner(ownerDriverSubsObject?.type);
      }}
      error={error}
      warn={warning}
      disabled={disabled}
      groupName='ownerDriver'
      isQuoteForMyself={isQuoteForMyself}
    />
  </FieldContainer>
);

const AboutCar: React.FC<Props> = ({
  toggleStatus,
  setProduct,
  setDriverOwner,
  submitFailed,
  licenceType,
  isQuoteForMyself,
  handleCarSubmit,
  quoteStep,
}) => {
  const productType = useSelector((state: RootState) => state.quote.product.productType);

  return (
    <>
      <Group id='quoteStart-component-groupCarToInsure'>
        <SectionTitle id='quoteStart-title-carToInsure'>
          Tell us about the car {isQuoteForMyself ? "you'd" : "they'd"} like to insure
          <span id='quoteStart-image-carIcon' role='img' aria-label='car'>
            {' '}
            🚗
          </span>
        </SectionTitle>
        {['csi', 'tc', 'newdriver'].includes(productType) && (
          <SectionSubTitle id='quoteStart-subtitle-borrowingTip'>
            {`TIP: If ${
              isQuoteForMyself ? 'your' : 'their'
            } partner or close family member is the registered keeper, we still call this
            borrowing`}
          </SectionSubTitle>
        )}
        <Field
          id='status'
          name='status'
          component={OwnerOrDriverSelector}
          validate={[ReduxFieldValidation.required]}
          setProduct={setProduct}
          setDriverOwner={setDriverOwner}
          toggleStatus={toggleStatus}
          isFullLicenceType={licenceType !== 'uk_prov'}
          isQuoteForMyself={isQuoteForMyself}
        />
      </Group>
      <Group id='quoteStart-component-groupCarDetails'>
        <CarSearch
          isVehicleLookup
          submitFailed={submitFailed}
          isQuoteForMyself={isQuoteForMyself}
          handleCarSubmit={handleCarSubmit}
          saveQuote
          quoteStep={quoteStep}
        />
      </Group>
    </>
  );
};

export default AboutCar;

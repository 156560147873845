import React from 'react';
import { Field, FormErrors, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/reducer';
import { getAge } from '@services/date';
import analyticsClient from '@utils/analytics';
import { SummaryLine, InformationBox } from '@rentecarlo/component-library';

import ReduxFieldValidation from '@services/formValidation';

import QuoteStep from '@templates/QuoteStep';

import SummaryBox from '@molecules/blocks/SummaryBox';

import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import Group from '@atoms/layout/form/Group';
import TextInput from '@atoms/inputs/TextInputField';
import { ComponentType, useComponentId } from '@hooks';

export interface DriverDetailsSummaryProps {
  address: string[];
  birthdate: string;
  canEdit: boolean;
  driverFullName: string;
  edit: () => void;
  email: string;
  licenceNumber: string;
  occupation: string;
  phone: string;
  hasEditedPersonalDetails: boolean;
  isLoggedIn: boolean;
  confirmAction: (
    props: DriverDetailsSummaryProps,
    formValues: Record<string, string>,
    mobileNumberUpdated: boolean,
  ) => void;
  productType: string;
  formErrors: FormErrors<object, string>;
  licenceType: string;
  paymentMethod: string;
  userIdentity: string;
}

const DriverDetailsSummary: React.FC<DriverDetailsSummaryProps & InjectedFormProps> = (props) => {
  const creator = useComponentId();
  const {
    address,
    birthdate,
    confirmAction,
    driverFullName,
    edit,
    email,
    licenceNumber,
    occupation,
    phone,
    productType,
    handleSubmit,
    formErrors,
    submitFailed,
    valid,
  } = props;

  const canEdit = true;

  const driverAge = useSelector((state: RootState) => state.quote.driver.birthdate);

  return (
    <QuoteStep
      id={creator(ComponentType.COMPONENT, 'quoteStep')}
      includeStepPrefix={false}
      nextAction={handleSubmit((values: Record<string, string>) => {
        const { phoneNumber } = values;
        const mobileNumberUpdated = !!(phoneNumber && phoneNumber !== phone);
        confirmAction(props, values, mobileNumberUpdated);
        const age = getAge(driverAge);
        analyticsClient.trackUserProperty('driverAge', age);
      })}
      nextLabel='Confirm'
      stepTitle='Driver details'
      stepSubtitle='Here’s the information we have about you, check the details below to see if they are all
      still correct.'
      form
      submitError={submitFailed && !valid}
      formErrors={formErrors}
    >
      <SummaryBox
        title='Your details'
        pillButtonAction={() => edit()}
        id={creator(ComponentType.BUTTON, 'edit')}
        hidePillButton={!canEdit}
      >
        <SummaryLine
          label='Name'
          values={[driverFullName]}
          id={creator(ComponentType.TEXT, 'driverName')}
        />
        <SummaryLine
          label='Date of birth'
          values={[birthdate]}
          id={creator(ComponentType.TEXT, 'driverDob')}
        />
        <SummaryLine
          label='Phone'
          values={[phone]}
          id={creator(ComponentType.TEXT, 'driverNumber')}
        />
        <SummaryLine
          label='Email'
          values={[email]}
          id={creator(ComponentType.TEXT, 'driverEmail')}
        />
        <SummaryLine
          label='Occupation'
          values={[occupation]}
          id={creator(ComponentType.TEXT, 'driverOccupation')}
        />
        <SummaryLine
          label='Address'
          values={[...address]}
          id={creator(ComponentType.TEXT, 'driverAddress')}
        />
        <SummaryLine
          label='Licence number'
          values={[licenceNumber]}
          id={creator(ComponentType.TEXT, 'driverLicenceNumber')}
        />
      </SummaryBox>
      {productType === 'newdriver' && (
        <form>
          <Group>
            <SectionTitle id={creator(ComponentType.TITLE)}>
              Driver’s mobile phone number
            </SectionTitle>
            <Field
              id={creator(ComponentType.FIELD, 'phoneNumber')}
              name='phoneNumber'
              type='tel'
              placeholder='e.g. 07123456789'
              component={TextInput}
              validate={[ReduxFieldValidation.isMobileNumber]}
              showInitialError
            />
            <InformationBox
              id={creator(ComponentType.FIELD, 'mobileImportantInformation')}
              type='important'
            >
              Please make sure that the driver’s mobile number is correct as we will use this to
              activate the New Driver app.
            </InformationBox>
          </Group>
        </form>
      )}
    </QuoteStep>
  );
};

export default DriverDetailsSummary;

import { connect } from 'react-redux';
import { isLoading } from '@reselectors/loading';
import { RootState } from '@redux/reducer';
import App from './App';

export interface ComponentProps {
  loading: boolean;
  loadingPrimaryText: string;
  children: React.ReactNode;
}

const mapStateToProps = (state: RootState): ComponentProps => {
  const {
    quote: {
      payment: { paymentText },
    },
  } = state;

  return {
    loading: isLoading(state),
    loadingPrimaryText: paymentText,
  };
};

export default connect(mapStateToProps, null)(App);

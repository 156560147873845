import React, { useEffect } from 'react';
import styled from 'styled-components';

import colors from '@config/colors';

const Container = styled.div`
  position: relative;
  height: 65px;
  width: 100%;
`;

const Input = styled.input`
  background-color: ${(props) => (props.disabled ? colors.grey.background.disabled : colors.white)};
  border: 1px solid ${colors.grey.lines.light};
  ${(props) => (props.border ? '' : `border-left: none; border-right: none;`)}
  border-radius: ${(props) => props.borderRadius};
  color: ${(props) => (props.value === '' ? colors.grey.text.light : colors.outerSpace)};
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: ${(props) => (props.value === '' ? '' : 'bold')};
  height: 65px;
  padding: 15px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'inherit')};
  ${(props) => (props.applyPlaceholderStyle ? props.placeholderStyle : props.textStyle)}
  &::placeholder {
    text-transform: none;
  }
  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
`;

const BottomBorder = styled.div`
  border-radius: ${(props) => props.borderRadius};
  ${(props) =>
    props.valid && props.value ? `border-bottom: 2px solid ${colors.green.selected}` : ''};
  ${(props) =>
    (props.error || props.tooShort) && props.touched
      ? `border-bottom: 2px solid ${colors.orange}`
      : ''};
  position: absolute;
  height: 5px;
  width: 100%;
  top: 60px;
`;

const DrivingLicenceInput = ({
  meta,
  setError,
  id,
  disabled,
  borderRadius,
  border,
  input,
  pasteDisabled,
  textStyle,
  placeholderStyle,
  maxLength,
  value,
  alwaysCapitalised,
}) => {
  const { name } = input;

  useEffect(() => {
    if (meta.error && meta.touched) {
      setError(name, meta.error);
    } else if (meta.valid) {
      setError(name, null);
    }
  }, [meta.error, meta.touched, meta.valid, setError, name]);

  return (
    <Container>
      <Input
        id={id}
        disabled={disabled}
        borderRadius={borderRadius}
        border={border}
        type='text'
        placeholder='- - - - -'
        value={input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onPaste={(e) => pasteDisabled && e.preventDefault()}
        textStyle={textStyle}
        placeholderStyle={placeholderStyle}
        applyPlaceholderStyle={value === ''}
        alwaysCapitalised={alwaysCapitalised}
        valid={meta.valid}
        maxLength={maxLength}
      />
      <BottomBorder
        borderRadius={borderRadius}
        valid={meta.valid}
        error={meta.error}
        value={input.value}
        touched={meta.touched}
        tooShort={input.value.length < maxLength}
      />
    </Container>
  );
};

DrivingLicenceInput.defaultProps = {
  disabled: false,
  onBlur: () => {},
  border: true,
};

export default DrivingLicenceInput;

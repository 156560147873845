type DecidedFeatures = {
  enabledFeatures: string[];
  disabledFeatures: string[];
};

export const getDecidedFeatures = (): DecidedFeatures => {
  const previouslyTrackedFeaturesString =
    sessionStorage.getItem('amplitude_tracked_features') ||
    JSON.stringify({
      enabledFeatures: [],
      disabledFeatures: [],
    });

  return JSON.parse(previouslyTrackedFeaturesString);
};

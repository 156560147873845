import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { saveQuote, saveAndGoTo } from '@reducer/quote/save.actions';
import { RootState } from '@redux/reducer';
import { setPaymentField } from '@reducer/quote/payment.actions';

import { matchesMobileRegex } from '@services/formValidation';

import FinalQuote from './FinalQuote';

const mapStateToProps = (state: RootState) => ({
  loggedIn: state.account.login.loggedIn,
  discountCode: state.quote.payment.discountCode,
  validDiscountCode: state.quote.save.validDiscountCode,
  hasConversionBonus: state.account.customer.hasConversionBonus,
  source: state.quote.user.source,
  utmSource: state.quote.user.utmSource,
  productType: state.quote.product.productType,
  isSubscription: state.quote.product.isSubscription,
  loading: state.quote.save.loading,
  breakdownCover: state.quote.ancillary.breakdownCover,
  isMobileNumber: matchesMobileRegex(state.quote.driver.phoneNumber),
  isQuoteForMyself: state.account.customer.isQuoteForMyself,
  alternativeProducts: state.quote.alternativeProducts,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  register: () => dispatch(push('quote-register')),
  continue: () => dispatch(saveAndGoTo('almost-there', false)),
  setDiscountCode: (discountCode: string) =>
    dispatch(setPaymentField('discountCode', discountCode)),
  saveQuote: () => dispatch(saveQuote()),
});

const FinalQuoteContainer = connect(mapStateToProps, mapDispatchToProps)(FinalQuote);

export default FinalQuoteContainer;

import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  InformationBox,
  P,
  LinkButton,
  RoundedGreyBox,
  InfoTextBox,
} from '@rentecarlo/component-library';
import colors from '@config/colors';
import dayjs, { Dayjs } from 'dayjs';

import { useOptimizelyFlag } from '@hooks';
import QuoteStep from '@templates/QuoteStep';
import PricingElement from '@molecules/blocks/PricingComponent.payment';
import SeamlessPriceDisplay from '@molecules/blocks/SeamlessPriceDisplay';
import PillButton from '@atoms/buttons/PillButton';
import { getIPIDUrl, getBreakdownCoverIPIDUrl } from '@reselectors/files';
import VoucherCode from '@organisms/blocks/VoucherCode';

import UpdateMobileNumberModal from '@organisms/modals/UpdateMobileNumberModal';
import SeamlessPassPriceModal from '@organisms/modals/SeamlessPassPriceModal';
import analyticsClient from '@utils/analytics';

const ProductInfoTextContainer = styled.div`
  font-family: proxima-soft;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.outerSpace};
`;

const Container = styled.div`
  margin-top: 30px;
`;

const AuthenticationButtons = styled.div`
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
`;

const ButtonWrapper = styled.div`
  padding: 4px;
  width: 100%;
`;

const IPIDButtonContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

const BreakdownIPIDButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  margin: 0 16px;
  font-weight: bold;
`;

const NumberedList = styled.ol`
  list-style-type: decimal;
  margin-top: 32px;
  margin-left: 0.75em;
  color: ${({ theme }) => theme.textPrimary};
`;

const SeamlessSwitchButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: ${colors.blue.royal};
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
`;

const SeamlessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const NumberedListItems = styled.li``;

Container.displayName = 'ButtonContainer';
AuthenticationButtons.displayName = 'AuthenticationButtons';

interface FinalQuoteProps {
  loggedIn: boolean;
  discountCode: string;
  validDiscountCode: boolean | null;
  hasConversionBonus: boolean;
  register: () => void;
  continue: () => void;
  saveQuote: () => void;
  setDiscountCode: (discountCode: string) => void;
  utmSource: string;
  productType: string;
  isSubscription: boolean;
  breakdownCover: boolean;
  isMobileNumber: boolean;
  loading: boolean;
  isQuoteForMyself: boolean;
  alternativeProducts: Record<string, unknown>;
}

export const buildTitleByProduct = (
  productType: string,
  isSubscription: boolean,
  isQuoteForMyself: boolean,
): string => {
  if (isSubscription) {
    return `${isQuoteForMyself ? 'Your' : 'Their'} final quote`;
  }
  if (productType === 'ldp') {
    return `${isQuoteForMyself ? 'Your' : 'Their'} final quote for learner driver insurance`;
  }
  if (productType === 'tc') {
    return `${isQuoteForMyself ? 'Your' : 'Their'} final quote for temporary insurance`;
  }
  return `${isQuoteForMyself ? 'Your' : 'Their'} final quote for car sharing insurance`;
};

const FinalQuote: React.FC<FinalQuoteProps> = ({
  validDiscountCode,
  discountCode,
  hasConversionBonus,
  saveQuote,
  setDiscountCode,
  isSubscription,
  productType,
  loggedIn,
  continue: continueQuote,
  register,
  breakdownCover,
  isMobileNumber,
  loading,
  isQuoteForMyself,
  alternativeProducts,
}) => {
  const TermsAndConditionsRef = useRef<HTMLOListElement | null>(null);

  const [showVoucherInput, setShowVoucherInput] = useState(false);
  const [hasSubmittedVoucher, setHasSubmittedVoucher] = useState(false);

  const [showMobileNumberModal, setShowMobileNumberModal] = useState(false);

  const scrollToTermsAndConditions = () => TermsAndConditionsRef.current?.scrollIntoView();

  const IPIDUrl = useSelector((state) => getIPIDUrl(state));
  const breakdownIPIDURL = useSelector((state) => getBreakdownCoverIPIDUrl(state));

  const isSeamlessEnabled = useOptimizelyFlag('TEMP_ENABLE_SEAMLESS').enabled;

  useEffect(() => {
    if ((productType === 'newdriver' || productType === 'seamless') && !isMobileNumber) {
      setShowMobileNumberModal(true);
    }
  }, [productType, isMobileNumber]);

  const [showPriceAfterPass, setShowPriceAfterPass] = useState(false);
  const [showPassPriceModal, setShowPassPriceModal] = useState(false);
  const [openedTimestamp, setOpenedTimestamp] = useState<Dayjs | null>(null);

  const handleToggle = () => {
    setShowPriceAfterPass(!showPriceAfterPass);
    analyticsClient.trackEvent('web_toggle_price_after_pass_final_quote');
  };

  const openPassPriceModal = (): void => {
    const timestamp = dayjs();
    analyticsClient.trackEvent('web_finalquote_seamless_pass_price_modal_open');
    setOpenedTimestamp(timestamp);
    setShowPassPriceModal(true);
  };

  const closePassPriceModal = (): void => {
    if (openedTimestamp) {
      const durationOpen = dayjs().diff(openedTimestamp, 'second');
      analyticsClient.trackEvent('web_finalquote_seamless_pass_price_modal_closed', {
        duration_open_in_seconds: durationOpen,
      });
    }
    setShowPassPriceModal(false);
  };
  const renderPromotionTermsAndConditions = (): React.ReactElement | null => {
    if (hasConversionBonus) {
      return (
        <NumberedList ref={TermsAndConditionsRef}>
          <NumberedListItems id='finalQuote-text-promotionTermsItemOne'>
            We reserve the right to remove this discount at any time without notice.
          </NumberedListItems>
          <NumberedListItems id='finalQuote-text-promotionTermsItemTwo'>
            This promotion only applies for UK Motor Policy Holders.
          </NumberedListItems>
          <NumberedListItems id='finalQuote-text-promotionTermsItemThree'>
            This promotion only applies to the final quote and will not apply to ancillary costs,
            such as lower excess and breakdown.
          </NumberedListItems>
        </NumberedList>
      );
    }
    return null;
  };

  const renderVoucherInput = (): React.ReactElement => {
    return (
      <div id='finalQuote-container-discount' style={{ padding: '8px' }}>
        {hasSubmittedVoucher &&
          !loading &&
          (!validDiscountCode ? (
            <InformationBox id='finalQuote-text-discountInvalidCode' type='error'>
              This is not a valid voucher code.
            </InformationBox>
          ) : (
            <InformationBox id='finalQuote-text-discountValidCode' type='important'>
              Your voucher code has been applied.
            </InformationBox>
          ))}
      </div>
    );
  };

  const renderSummaryFooter = (): React.ReactElement => {
    return (
      <>
        {isSubscription && productType !== 'seamless' && (
          <TextContainer>
            <P fontSize={14} id='finalQuote-text-noFeeCancelText'>
              Cancel anytime with no fees ✅
            </P>
          </TextContainer>
        )}
        <VoucherCode
          breakdownCover={breakdownCover}
          discountCode={discountCode}
          hasConversionBonus={hasConversionBonus}
          saveQuote={saveQuote}
          setDiscountCode={setDiscountCode}
          validDiscountCode={validDiscountCode}
          showVoucherInput={showVoucherInput}
          setShowVoucherInput={setShowVoucherInput}
          setHasSubmittedVoucher={setHasSubmittedVoucher}
          scrollToTermsAndConditions={scrollToTermsAndConditions}
        />
      </>
    );
  };

  const getProductInfoBasedOnEligibility = (): React.ReactElement | null => {
    if (productType === 'ldp' && isSubscription) {
      if (alternativeProducts?.seamless?.passedUW === false) {
        return (
          <Container>
            <InfoTextBox state='INFO' id='finalQuote-not-eligible-for-seamless-info'>
              <ProductInfoTextContainer id='finalQuote-not-eligible-for-seamless-info'>
                {isQuoteForMyself ? 'Your' : 'Their'} <b>Learner Insurance</b> covers you{' '}
                <b>until {isQuoteForMyself ? 'you' : 'they'} pass</b>, then{' '}
                {isQuoteForMyself ? `you'll` : `they'll`} need to cancel it. Based on the
                information provided, we <b>can’t</b> offer {isQuoteForMyself ? 'you' : 'them'}{' '}
                combined <b>Learner + New Driver cover</b>. Once{' '}
                {isQuoteForMyself ? `you've` : `they've`} passed, come back for a New Driver quote,
                we’ll give {isQuoteForMyself ? 'you' : 'them'} <b>10% off any policy you take.</b>
              </ProductInfoTextContainer>
            </InfoTextBox>
          </Container>
        );
      }
      if (alternativeProducts?.seamless?.passedUW === true) {
        return (
          <SeamlessSwitchButtonContainer>
            <PillButton
              id='finalQuote-button-seamlessSwitch'
              onClick={() => {
                window.location.href = '/product-choice';
                analyticsClient.trackEvent('web_switch_to_seamless_offer_final_quote');
              }}
              background={colors.grey.background.lighter}
            >
              Switch to Learner + New Driver offer
            </PillButton>
          </SeamlessSwitchButtonContainer>
        );
      }
    }
    return null;
  };

  return (
    <>
      <UpdateMobileNumberModal
        visible={showMobileNumberModal}
        close={() => setShowMobileNumberModal(false)}
        isQuoteForMyself={isQuoteForMyself}
      />
      <QuoteStep
        id='finalQuote-component-quoteStep'
        stepTitle={buildTitleByProduct(productType, isSubscription, isQuoteForMyself)}
        stepSubtitle={`Based on the details you have provided, here is the final price for ${
          isQuoteForMyself ? 'your' : 'their'
        } ${isSubscription ? 'monthly subscription' : 'policy'}.`}
      >
        {productType !== 'seamless' ? (
          <PricingElement
            quoteSummaryHeader={
              isSubscription
                ? `${isQuoteForMyself ? 'Your' : 'Their'} monthly subscription`
                : `${isQuoteForMyself ? 'Your' : 'Their'} quote`
            }
            quoteSummaryFooter={renderSummaryFooter()}
            showNextPayment={false}
            isTelematics={productType === 'newdriver'}
          />
        ) : (
          <SeamlessContainer>
            <SeamlessPriceDisplay
              leftActive={!showPriceAfterPass}
              handleToggle={handleToggle}
              renderPromoCode={renderSummaryFooter()}
              showPolicyDetails
            />
            {showPriceAfterPass && (
              <RoundedGreyBox>
                <ProductInfoTextContainer id='finalQuote-first-month-price-info'>
                  <sup>*</sup> For the <b>first month after you pass</b>, you’ll be charged your
                  <b> learner price</b>. After that, your price will change. There’s an estimate
                  above, but
                  <b> we’ll confirm the final price when you pass</b>.{' '}
                  <LinkButton
                    id='finalQuote-why-price-increase'
                    action={() => openPassPriceModal()}
                    bold
                  >
                    Why does my price go up?
                  </LinkButton>
                </ProductInfoTextContainer>
                <SeamlessPassPriceModal
                  show={showPassPriceModal}
                  close={() => closePassPriceModal()}
                  isQuoteForMyself={isQuoteForMyself}
                />
              </RoundedGreyBox>
            )}
          </SeamlessContainer>
        )}{' '}
        {showVoucherInput || validDiscountCode ? renderVoucherInput() : null}
        {isSeamlessEnabled && getProductInfoBasedOnEligibility()}
        <Container id='finalQuote-container'>
          {loggedIn ? (
            <Button
              id='finalQuote-button-continueToPayment'
              onClick={() => continueQuote()}
              height={64}
              borderWidth={0}
            >
              Continue to payment
            </Button>
          ) : (
            <ButtonWrapper id='finalQuote-container-registerButton'>
              <Button
                id='finalQuote-button-register'
                onClick={register}
                height={64}
                borderWidth={0}
              >
                Register
              </Button>
            </ButtonWrapper>
          )}
        </Container>
        <IPIDButtonContainer>
          <PillButton
            id='finalQuote-button-IPIDPill'
            onClick={() => {
              window.open(IPIDUrl, '_blank');
            }}
          >
            Read our Product Information Guide
          </PillButton>
        </IPIDButtonContainer>
        {breakdownCover && (
          <BreakdownIPIDButtonContainer>
            <PillButton
              id='finalQuote-button-breakdownIPID'
              onClick={() => {
                window.open(breakdownIPIDURL, '_blank');
              }}
            >
              Breakdown Cover Information Guide
            </PillButton>
          </BreakdownIPIDButtonContainer>
        )}
        {renderPromotionTermsAndConditions()}
      </QuoteStep>
    </>
  );
};

export default FinalQuote;

import { AppTypeActions, types } from './actions';
import { types as loginTypes, LoginActiontypes } from '../account/login.actions';
import deepUpdate from '../../../services/deepUpdate';

export interface AppState {
  size: {
    width: number;
    height: number;
  };
  isMobile: boolean;
  showAlertBanner: boolean;
  completedEarlyLicenceCheck: boolean;
}

// Reducer
export const initialState: AppState = {
  size: {
    width: 0,
    height: 0,
  },
  isMobile: false,
  showAlertBanner: true,
  completedEarlyLicenceCheck: false,
};

export default function app(
  state = initialState,
  action: AppTypeActions | LoginActiontypes,
): AppState {
  switch (action.type) {
    case types.SET_APP_FIELD:
      return deepUpdate(state, action.field, action.value);
    case types.SET_SIZE:
      return {
        ...state,
        size: {
          width: action.width,
          height: action.height,
        },
      };
    case types.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      };
    case types.HIDE_ALERT_BANNER:
      return {
        ...state,
        showAlertBanner: false,
      };
    case loginTypes.LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

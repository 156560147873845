import React, { ReactElement, useState } from 'react';
import {
  StyledSelector,
  StyledSelectorImg,
  StyledWrapper,
  StyledLeftWrapper,
  StyledRightWrapper,
  StyledUl,
  StyledLi,
  StyledOptionImg,
} from './assets/styles';

import blueArrow from './assets/blue-arrow.svg';
import greenTick from './assets/green-tick.svg';

interface Props {
  id: string;
  options: {
    name: string;
    value: string;
  }[];
  placeholder: string;
  onChange: (name: string) => void;
  value: string;
  borderBottomColor: string;
  visibleItemCount?: number;
}

const SelectNew = ({
  id,
  options,
  placeholder,
  onChange,
  value,
  borderBottomColor,
  visibleItemCount = 5,
}: Props): ReactElement => {
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = (): void => {
    setOpen((openValue) => !openValue);
  };

  const onChangeAndToggleDropdown = (newValue: string): void => {
    onChange(newValue);
    toggleDropdown();
  };

  const { name } = options.find((item) => item.value === value) ?? { name: '' };

  return (
    <>
      <StyledSelector
        id={id}
        onClick={(): void => toggleDropdown()}
        placeholder={placeholder}
        value={value}
        isOpen={isOpen}
        borderBottomColor={borderBottomColor}
      >
        <StyledLeftWrapper id='car-text-selectNew'>{name || placeholder}</StyledLeftWrapper>
        <StyledRightWrapper>
          <StyledSelectorImg
            id='car-image-selectNew'
            src={blueArrow}
            isOpen={isOpen}
            alt='blue-arrow'
          />
        </StyledRightWrapper>
      </StyledSelector>
      <StyledWrapper>
        <StyledUl id='car-text-selectNewList' isOpen={isOpen} visibleItemCount={visibleItemCount}>
          {options.map((option) => (
            <StyledLi
              id={`car-text-selectNewOption${option.value}`}
              key={option.value}
              onClick={(): void => onChangeAndToggleDropdown(option.value)}
              isSelected={value === option.value}
            >
              <StyledLeftWrapper id='car-container-selectNew'>{option.name}</StyledLeftWrapper>
              <StyledRightWrapper>
                <StyledOptionImg
                  src={greenTick}
                  isVisible={value === option.value}
                  alt='green-tick'
                />
              </StyledRightWrapper>
            </StyledLi>
          ))}
        </StyledUl>
      </StyledWrapper>
    </>
  );
};

export default SelectNew;

import React from 'react';

import { PageModal, Button } from '@rentecarlo/component-library';
import { ComponentType, useComponentId } from '@hooks';
import styled from 'styled-components';
import colors from '@config/colors';
import media from '@config/media';
import fonts from '@config/fonts';

interface Props {
  show: boolean;
  close: () => void;
  fakeDoorTestSubmission: () => void;
}

export const Title = styled.h1`
  font-family: ${fonts.title};
  font-weight: bold;
  line-height: 36px;
  font-size: 32px;
  color: ${colors.dark.title};
  ${media.tablet`
    line-height: 32px;
    font-size: 25px;
    letter-spacing: 0.9px;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin: 20px 0 60px 0;
`;

const Text = styled.p`
  color: ${colors.black};
  font-family: 'proxima-soft';
  font-size: 16px;
`;

export const SeamlessFakeDoorTestModal: React.FC<Props> = ({
  show,
  close,
  fakeDoorTestSubmission,
}) => {
  const closeText = 'Close';
  const creator = useComponentId();
  return (
    <PageModal close={close} closeText={closeText} show={show}>
      <Container>
        <Title id={creator(ComponentType.TITLE, 'PaperDocsModalTitle')}>
          Thank you for your interest!
        </Title>
        <Text id={creator(ComponentType.TEXT, 'PaperDocsModalDescription')}>
          Thank you for your interest in our <b>Learner + New Driver cover</b>!
          <br />
          <br />
          While <b>this cover isn’t available just yet</b>, we’re working hard to bring it to you as
          soon as possible.
          <br />
          <br />
          Good luck with your driving practice! 🚗
        </Text>
      </Container>
      <Button
        id={creator(ComponentType.BUTTON, 'seamlessOfferContinueWithSeamless')}
        onClick={() => fakeDoorTestSubmission()}
      >
        I Understand
      </Button>
    </PageModal>
  );
};

export default SeamlessFakeDoorTestModal;

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const RESTRICTION_START_HOUR = 22;
export const RESTRICTION_END_HOUR = 5;

const weekdayAbbreviations = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const createDayjsFromDateAndHour = (date, time) => {
  const { hour, minute } = time;

  return dayjs(date)
    .hour(hour || 0)
    .minute(minute || 0)
    .second(0);
};

export const getDaysAndHoursFromHours = (hours) => {
  const days = Math.floor(hours / 24);
  // eslint-disable-next-line no-param-reassign
  hours %= 24;

  if (days > 0) {
    return `${days} days ${hours} hours`;
  }
  return `${hours} hours`;
};

export const getDaysFromHours = (hours) => Math.floor(hours / 24);

export const getAge = (birthdate) => dayjs().diff(birthdate, 'years');

export const isOutsideRange = (day, toOrFrom, currentFromDate, currentToDate) => {
  // day is in the past
  if (day.isBefore(dayjs(), 'day')) return true;
  // from date is after 60 days
  if (toOrFrom === 'from' && day.isAfter(dayjs().add(60, 'days'), 'day')) return true;
  // to date is before from date
  if (toOrFrom === 'to' && day.isBefore(currentFromDate, 'day')) return true;
  // from date is after to date
  if (toOrFrom === 'from' && day.isAfter(currentToDate)) return true;

  return false;
};

export const buildDateForApi = (dateObject) => {
  if (!dateObject) return null;

  return dateObject.format('YYYY-MM-DD');
};

export const isBetween22and5 = (date) => {
  if (!date) return null;

  return date.hour() >= RESTRICTION_START_HOUR || date.hour() < RESTRICTION_END_HOUR;
};

export const isForbiddenDuration = (start, end) => {
  const endDate = dayjs(end);
  const startDate = dayjs(start);
  const durationLessThan24h = endDate.diff(startDate, 'hour') < 24;

  return durationLessThan24h && (isBetween22and5(startDate) || isBetween22and5(endDate));
};

export const calculateDurationInHours = (start, end) => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);

  return Number((endDate.diff(startDate, 'minutes') / 60).toFixed(2));
};

export const formatDate = (date) => dayjs(date).format('Do MMMM YYYY');

export const formatWeekday = (date) => weekdayAbbreviations[dayjs(date).day()];

export const formatMonth = (date) => monthNames[dayjs(date).month()];

export const createNativeDateFromDayjs = (date) => {
  const dayjsDate = dayjs.isDayjs(date) ? date : dayjs(date);
  return dayjsDate.isValid() ? dayjsDate.toDate() : null;
};

export const createDayjsFromNativeDate = (date) => {
  return dayjs(date.toISOString());
};

export const formatNativeDate = (date) => createDayjsFromNativeDate(date).format('D MMMM YYYY');

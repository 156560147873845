/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */
import * as amplitude from '@amplitude/analytics-browser';
import type { ValidPropertyType } from '@amplitude/analytics-types';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { getDecidedFeatures } from './optimizely';

export interface CookieYesConsentEvent extends Event {
  detail: { accepted: string[]; rejected: string[] };
}

class AmplitudeClient {
  private amplitudeInitialised = false;
  private amplitudeKey = '';
  private sessionReplayTracking = sessionReplayPlugin({ forceSessionTracking: true });
  private cognitoUserId: string | undefined;

  public async init(amplitudeEnabled: boolean, amplitudeKey: string): Promise<void> {
    this.amplitudeKey = amplitudeKey;

    if (amplitudeEnabled) {
      await this.optIn();
    }

    this.setupConsentListener();
  }

  public getCognitoId(): string | undefined {
    return this.cognitoUserId;
  }

  public trackEvent(eventName: string, eventProperties?: object): void {
    amplitude.track(eventName, { ...eventProperties, app: 'quote' });
  }

  public trackPurchase(productId: string, price: number, eventProperties = {}): void {
    const revenueEvent = new amplitude.Revenue()
      .setProductId(productId)
      .setPrice(price)
      .setQuantity(1)
      .setEventProperties(eventProperties);

    amplitude.revenue(revenueEvent);
  }

  public trackUserProperty(property: string, value: ValidPropertyType): void {
    const identityEvent = new amplitude.Identify();
    identityEvent.set(property, value);
    amplitude.identify(identityEvent);
  }

  private setupConsentListener(): void {
    document.addEventListener('cookieyes_consent_update', async (event: Event) => {
      const eventData = event as CookieYesConsentEvent;
      const data = eventData.detail;

      if (data.accepted.includes('analytics')) {
        await this.optIn();
        this.trackEvent('web_page_viewed', { pageName: window.location.pathname });
      }
      if (data.rejected.includes('analytics')) {
        this.optOut();
      }
    });
  }

  public setUserId(userId: string): void {
    if (!this.cognitoUserId) {
      this.cognitoUserId = userId;
      amplitude.setUserId(userId);
    }
  }

  public unSetUserId(): void {
    if (this.cognitoUserId !== undefined) {
      this.cognitoUserId = undefined;
      amplitude.setUserId(undefined);
    }
  }

  private optOut(): void {
    if (this.amplitudeInitialised) {
      amplitude.setOptOut(true);
      amplitude.remove(this.sessionReplayTracking.name as string);
    }
  }

  private async optIn(): Promise<void> {
    if (this.amplitudeInitialised) {
      amplitude.setOptOut(false);
      return;
    }

    await this.initAmplitudeWithSessionReplay();
    this.amplitudeInitialised = true;

    this.storeDeviceId();
    this.trackEvent('web_feature_flags', getDecidedFeatures());
  }

  private async initAmplitudeWithSessionReplay(): Promise<void> {
    await amplitude.add(this.sessionReplayTracking).promise;

    await amplitude.init(this.amplitudeKey, {
      serverZone: 'EU',
      autocapture: {
        attribution: false,
        pageViews: false,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: {
          pageUrlAllowlist: [/\/car$/],
        },
      },
      defaultTracking: {
        sessions: true,
      },
      optOut: false,
      logLevel: amplitude.Types.LogLevel.Error,
    }).promise;
  }

  private storeDeviceId(): void {
    const deviceId = amplitude.getDeviceId();
    if (deviceId) {
      sessionStorage.setItem('amplitude_device_id', deviceId);
    }
  }
}

export default AmplitudeClient;

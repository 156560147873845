import Debug from 'debug';
import { push } from 'connected-react-router';
import { take, call, put, select, takeEvery, race } from 'redux-saga/effects';

import { types as customerTypes } from '@reducer/account/customer.actions';
import { setVariableExcess } from '@reducer/quote/importantInformation.actions';
import {
  updatePrice,
  types,
  saveQuoteSuccess,
  saveQuoteFailure,
  setSaveField,
  saveAndGoTo,
  saveQuote,
} from '@reducer/quote/save.actions';
import { updateInitialPriceWithBreakdownCover } from '@reducer/quote/ancillary.actions';
import { customerLicenceTypeSelector } from '@reselectors/account';
import {
  getDefaultExcessForProduct,
  getIsSubscription,
  getProductType,
  getPreviousProductType,
  getDriverOwner,
  getHasPassedTest,
} from '@reselectors/quote';
import quoteApi from '@services/api/quote';
import { types as licenceTypes } from '@redux/reducer/quote/licence.actions';
import { LicenceTypes } from '@redux/reducer/quote/licence';
import { setProduct } from '@redux/reducer/quote/product.actions';
import { setAlternativeProduct } from '@redux/reducer/quote/alternativeProducts.actions';
import { optimizelyClient } from '@services/clients/optimizely';
import {
  getOptimizelyUserIdentity,
  optimizelyOverrideAttributes,
} from '@redux/reselectors/optimizely';
import sendOptimizelyEvent from '@services/sendOptimizelyEvent';

import getPages from '@services/pageOrderValidation';

import handleError from '@redux/utils/errorHandler';

import { lookupCar } from '@reducer/lookup/actions';

import analyticsClient from '@utils/analytics';

import { DirtyState } from '../utils/dirtyState';
import { sendEvent } from '../reducer/analytics/actions';

const stateUpdates = DirtyState.getInstance();

const debug = Debug('veygo:sagas:save');

function* determineExcessAndPrice() {
  // checks that the selected excess still exists:
  // if so it updates the price else it resets to default excess
  const finalPricesByExcess = yield select(
    (state) => state.quote.importantInformation.finalPricesByExcess,
  );
  if (finalPricesByExcess.length > 0) {
    const excessSelected = yield select((state) => state.quote.importantInformation.excessSelected);
    const currentExcessAndPrice = finalPricesByExcess.find(
      (element) => element.excess === excessSelected,
    );
    if (currentExcessAndPrice === undefined) {
      const defaultExcess = yield select((state) => getDefaultExcessForProduct(state));
      yield put(setVariableExcess(defaultExcess));
    } else {
      const currentURL = yield select((state) => state.router.location.pathname);
      if (currentURL.includes('driving-history')) {
        yield put(updatePrice(currentExcessAndPrice.price));
      }
    }
  }
}

function* determineInitialPriceWithBreakdown() {
  const isBreakdownCover = yield select((state) => state.quote.ancillary.breakdownCover);
  const finalPricesByExcess = yield select(
    (state) => state.quote.importantInformation.finalPricesByExcess,
  );
  const excessSelected = yield select((state) => state.quote.importantInformation.excessSelected);

  if (finalPricesByExcess.length > 0 && excessSelected && isBreakdownCover) {
    const price = finalPricesByExcess.find((element) => element.excess === excessSelected);
    if (price !== undefined) {
      const priceWithBreakdown = price.price_with_breakdown.toString() || null;
      yield put(updateInitialPriceWithBreakdownCover(priceWithBreakdown));
    }
  } else {
    yield put(updateInitialPriceWithBreakdownCover(null));
  }
}

// eslint-disable-next-line consistent-return
function* fetchAltProduct(
  uuid,
  productType,
  isSubscription = null,
  duration = null,
  storedName = null,
) {
  const debugSave = debug.extend('saveQuoteSaga');
  try {
    const { passed_uw: passedUW, price } = yield call(
      quoteApi.getAltProduct,
      uuid,
      productType,
      isSubscription,
      duration,
    );
    yield put(setAlternativeProduct(storedName ?? productType, { passedUW, price }));

    return { passedUW, price };
  } catch (error) {
    debugSave('error', error);
  }
}

function* shouldCheckAltProduct() {
  const productType = yield select(getProductType);
  const previousProductType = yield select(getPreviousProductType);
  const isSubscription = yield select(getIsSubscription);

  const eligibleProducts = ['tc'];

  const userIdentity = yield select(getOptimizelyUserIdentity);
  const dtcCrossSellEnabled = optimizelyClient.isFeatureEnabled(
    'TEMP_ENABLE_DTC_NEWDRIVER_CROSS_SELL_SUBS',
    userIdentity,
    {
      user_identity: userIdentity,
    },
  );
  const seamlessEnabled = optimizelyClient.isFeatureEnabled('TEMP_ENABLE_SEAMLESS', userIdentity, {
    user_identity: userIdentity,
  });
  const seamlessFakeDoorEnabled = optimizelyClient.isFeatureEnabled(
    'TEMP_ENABLE_SEAMLESS_FAKE_DOOR',
    userIdentity,
    {
      user_identity: userIdentity,
    },
  );

  if (dtcCrossSellEnabled) {
    eligibleProducts.push('csi');
  }

  if (seamlessEnabled || seamlessFakeDoorEnabled) {
    eligibleProducts.push('ldp');
  }

  return (
    (eligibleProducts.includes(productType) ||
      (productType === 'newdriver' && eligibleProducts.includes(previousProductType))) &&
    isSubscription
  );
}

// eslint-disable-next-line consistent-return
function* fetchLicenceCheck(uuid) {
  const debugSave = debug.extend('saveQuoteSaga');
  try {
    const { stated_licence_type_matches_licence_call: licenceMatch } = yield call(
      quoteApi.licenceCheck,
      uuid,
    );
    return licenceMatch;
  } catch (error) {
    debugSave('error', error);
  }
}

function* sendPageVisitEvents(page) {
  const pagesToSend = {
    'final-quote': 'pageVisit-finalQuote',
    'duration-of-cover': 'pageVisit-durationOfCover',
  };

  if (pagesToSend[page]) {
    const overrideAttributes = yield select(optimizelyOverrideAttributes);
    sendOptimizelyEvent(pagesToSend[page], overrideAttributes);
  } else {
    yield;
  }
}

function* sendRejectionReasonsToAnalytics() {
  const rejectionReasons = yield select((state) => state.quote.save.rejectionReasons);

  if (!rejectionReasons || !rejectionReasons.length) {
    return;
  }

  // We only care about the reasons that start with 'FR'
  const filteredReasons = rejectionReasons
    .filter(({ reason }) => /^FR/.test(reason))
    .map(({ reason }) => reason);

  if (!filteredReasons.length) {
    return;
  }

  yield analyticsClient.trackEvent('web_quote_rejection', { rejectionReasons: filteredReasons });
}

function* doesCustomerLicenceTypeMatchQuote() {
  const hasUpdatedLicenceType = yield select((state) => state.quote.licence.hasUpdatedLicenceType);
  if (hasUpdatedLicenceType) {
    return true;
  }

  const customerLicenceType = yield select(customerLicenceTypeSelector);
  const quoteLicenceType = yield select((state) => state.quote.licence.type);

  return customerLicenceType === quoteLicenceType;
}

function* checkEligibilityForTemporaryCover(
  checkProductUw,
  data,
  shouldCheckNewDriver,
  altProductData,
  state,
) {
  const {
    quote: {
      product: { isSubscription, productType },
      save: { uuid },
    },
  } = state;

  const rejected = data.underwriting_criteria === false || data.price === null;
  const newDriverRejected = shouldCheckNewDriver
    ? altProductData?.passedUW === false || altProductData?.price === null
    : true;

  if (checkProductUw && rejected && newDriverRejected && isSubscription) {
    yield call(fetchAltProduct, uuid, productType, false, 720, 'tempcover');
  }
}

function getDestinationOverride(
  checkProductUw,
  destinationAlreadySet,
  data,
  page,
  productChoiceShown,
  altProductData,
  productType,
  isFastRepurchase,
) {
  if (!checkProductUw || destinationAlreadySet) return null;

  const failedProductUW = !data.underwriting_criteria || data.price === null;
  const fastRepurchaseEditing = isFastRepurchase && page !== '/final-quote';

  if (failedProductUW) {
    if (fastRepurchaseEditing) return null;

    return 'rejection';
  }

  const hasAltProductPrice = altProductData && altProductData.price;
  let showProductChoice =
    hasAltProductPrice &&
    (productType === 'newdriver' || altProductData.price <= Number(data.price)) &&
    !productChoiceShown;

  if (productType === 'ldp' && altProductData && altProductData.passedUW) {
    showProductChoice = true;
  }

  if (showProductChoice) return 'product-choice';

  return null;
}

export function* saveQuoteSaga({ page, checkProductUw, checkLicence, productChoiceShown }) {
  const debugSave = debug.extend('saveQuoteSaga');
  debugSave('start');

  const isLoggedIn = yield select((state) => state.account.login.loggedIn);
  const isNewCar = yield select((state) => state.quote.car.isNewCar);
  const isBreakdownCover = yield select((state) => state.quote.ancillary.breakdownCover);

  const shouldCreateAncillary = (ancillaryId) => isBreakdownCover && !ancillaryId;
  const shouldUpdateAncillary = (ancillaryId) => isBreakdownCover && ancillaryId;

  const save = yield select((state) => state.quote.save);
  let callee;
  let ancillaryId;

  const userIdentity = yield select(getOptimizelyUserIdentity);

  if (save && save.uuid) {
    debugSave('save, save.uuid', save, save.uuid);
    ancillaryId = save.ancillaryUuid;
    if (shouldCreateAncillary(ancillaryId)) {
      debugSave('creating ancillary');
      const ancillaryResponse = yield call(quoteApi.createAncillary, save.uuid);
      ancillaryId = ancillaryResponse.ancillary_id;
    } else if (shouldUpdateAncillary(ancillaryId)) {
      yield call(quoteApi.updateAncillary, save.uuid, ancillaryId);
    }
    if (!isBreakdownCover && ancillaryId) {
      yield call(quoteApi.deleteAncillary, save.uuid, ancillaryId);
      ancillaryId = null;
    }
    const quote = yield select((state) => state.quote);

    debugSave('updating quote');
    callee = call(quoteApi.update, save.uuid, quote);
  } else {
    debugSave('creating quote');
    const quote = yield select((state) => state.quote);
    callee = call(quoteApi.create, quote);
  }

  try {
    debugSave('calling api');

    const onlyUpdateQuoteOnChange = optimizelyClient.isFeatureEnabled(
      'TEMP_UPDATE_QUOTE_ON_CHANGE',
      userIdentity,
      {
        user_identity: userIdentity,
      },
    );

    let data;
    if (onlyUpdateQuoteOnChange) {
      const quote = yield select((state) => state.quote);
      const currentURL = yield select((state) => state.router.location.pathname);

      if (stateUpdates.shouldCall(quote, currentURL)) {
        data = yield callee;
        stateUpdates.setStoredResponse(quote, data);
      } else {
        data = stateUpdates.getStoredResponse();
      }
    } else {
      data = yield callee;
    }

    let licenceMatch;
    if (checkLicence) {
      licenceMatch = yield call(fetchLicenceCheck, save.uuid);
    }

    const productType = yield select(getProductType);

    let altProductData;
    const shouldCheckNewDriver = yield call(shouldCheckAltProduct);
    if (checkProductUw && shouldCheckNewDriver) {
      const isRejectionEligible =
        (!data.underwriting_criteria || data.price == null) && productType !== 'newdriver';

      const isSuccessEligible = data.underwriting_criteria && data.price;

      if (isRejectionEligible || isSuccessEligible) {
        const previousProductType = yield select(getPreviousProductType);

        let altProductType;
        if (productType === 'ldp') {
          altProductType = 'seamless';
        } else if (productType === 'newdriver') {
          altProductType = previousProductType;
        } else {
          altProductType = 'newdriver';
        }

        altProductData = yield call(fetchAltProduct, save.uuid, altProductType);
      }
    }

    yield checkEligibilityForTemporaryCover(
      checkProductUw,
      data,
      shouldCheckNewDriver,
      altProductData,
      yield select(),
    );

    // if a customer chooses an excess, then goes back and is no longer eligible for that excess
    // then pricing returns null so their excess has to be reset
    const selectedExcess = yield select((state) => state.quote.importantInformation.excessSelected);
    if (data.excess !== selectedExcess) {
      yield put(setVariableExcess(data.excess));
    }

    data.isLoggedIn = isLoggedIn;
    data.isNewCar = isNewCar;
    data.ancillary_id = ancillaryId;

    // Setup the redirection for post save
    let destination;
    let destinationState;

    if (page) {
      const doesCustomerLicenceTypeMatchQuoteResult = yield doesCustomerLicenceTypeMatchQuote();

      const doesLicenceMatchCall =
        typeof licenceMatch === 'boolean'
          ? licenceMatch
          : data.stated_licence_type_matches_licence_call;

      if (checkLicence && data.licence_type === 'ukp' && doesLicenceMatchCall) {
        destination = 'driving-test';
        destinationState = { destination: page, checkProductUw };
      } else if (
        !doesLicenceMatchCall ||
        (checkProductUw && isLoggedIn && !doesCustomerLicenceTypeMatchQuoteResult)
      ) {
        destination = 'licence-switch';
        destinationState = { destination: page, checkProductUw };
      }
    }

    const isFastRepurchase = yield select((state) => state.quote.save.isFastRepurchase);
    const destinationOverride = getDestinationOverride(
      checkProductUw,
      destination,
      data,
      page,
      productChoiceShown,
      altProductData,
      productType,
      isFastRepurchase,
    );

    destination = destinationOverride || destination || page;

    // Check for excess
    const meta = { targetPage: destination };

    debugSave('putting saveQuoteSuccess', meta);
    yield put(saveQuoteSuccess(data, meta));
    yield call(sendPageVisitEvents, page);

    yield determineExcessAndPrice();

    yield determineInitialPriceWithBreakdown();

    if (destination) {
      debugSave(`pushing to ${destination}`);
      yield put(push(destination, destinationState));
    }
  } catch (error) {
    debugSave('error', error);

    if (error.code === 'UNCONFIRMEDPROVISIONAL') {
      const hasPassedTest = yield select(getHasPassedTest);
      if (typeof hasPassedTest === 'boolean') {
        yield put(push('licence-switch', { destination: page, checkProductUw }));
      } else {
        yield put(push('driving-test', { destination: page, checkProductUw }));
      }
    }
    yield put(saveQuoteFailure(error));
    stateUpdates.resetStoredResponse();
  }
}

export function* sendGAEvent(action) {
  if (action.data.reference_number) {
    yield put(
      sendEvent('quoteReference', {
        quoteReference: action.data.reference_number,
        quoteUUID: action.data.uuid,
      }),
    );
  }
}

export function* genericQuoteSaveError({ error }) {
  if (error.code === 'THIRDPARTYFAILURE') {
    yield put(push('/error'));
  }
}

export function* updateLicence({ licenceType, page, checkProductUw }) {
  try {
    let productChanged = false;

    if ([LicenceTypes.uk_manual, LicenceTypes.uk_auto].includes(licenceType)) {
      const productType = yield select(getProductType);

      if (productType === 'ldp') {
        const isDriverOwner = yield select(getDriverOwner);
        const newProductType = isDriverOwner ? 'tc' : 'csi';

        yield put(setProduct(newProductType));
        productChanged = true;
      }
    } else if (licenceType === LicenceTypes.uk_prov) {
      yield put(setProduct('ldp'));
      productChanged = true;
    }

    let redirect;
    if (productChanged) {
      // Check page order guard now product has changed
      const pagesState = yield select((state) => getPages(state));

      if (pagesState.findIndex((value) => value.url.includes(page)) > -1) {
        pagesState.every((pageState) => {
          if (pageState.url.includes(page)) return false;

          if (!pageState.hasRequiredData) {
            redirect = pageState.url.replace('/', '');
            return false;
          }

          return true;
        });
      }
    }
    yield put(saveAndGoTo(redirect ?? page, redirect ? false : checkProductUw));
  } catch (error) {
    handleError(error);
  }
}

function* saveQuoteAndLookupCar({ carRegistration }) {
  yield put(saveQuote());

  const { success } = yield race({
    success: take(types.SAVE_QUOTE_SUCCESS),
    failure: take(types.SAVE_QUOTE_FAILURE),
  });

  if (success) {
    yield put(lookupCar(carRegistration));
  }
}

export function* watchSave() {
  yield takeEvery(types.SAVE_QUOTE_REQUEST, saveQuoteSaga);
  yield takeEvery(types.SAVE_QUOTE_SUCCESS, sendGAEvent);
  yield takeEvery(types.SAVE_QUOTE_SUCCESS, sendRejectionReasonsToAnalytics);
  yield takeEvery(types.SAVE_QUOTE_FAILURE, genericQuoteSaveError);
  yield takeEvery(licenceTypes.UPDATE_LICENCE, updateLicence);
  yield takeEvery(types.SAVE_QUOTE_AND_LOOKUP_CAR, saveQuoteAndLookupCar);
}

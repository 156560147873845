import styled from 'styled-components';
import colors from '@config/colors';
import fonts from '@config/fonts';

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  border-radius: 4px;
  background-color: ${colors.green.mint};
  width: 100%;
  margin-bottom: 10px;
`;

export const Price = styled.div`
  text-align: center;
  color: ${colors.white};
  font-family: ${fonts.title};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 38px;
`;

export const Pound = styled.div`
  color: ${colors.white};
  font-family: ${fonts.title};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-right: 2px;
`;

export const PriceInner = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Loader = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 30px 0 20px;
`;

export const MonthText = styled.div`
  align-self: flex-end;
  color: ${colors.white};
  font-family: ${fonts.standard};
  font-size: 18px;
  font-weight: 400;
  padding-left: 4px;
`;

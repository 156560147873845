/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Info } from '@rentecarlo/component-library';
import { useOptimizelyFlag } from '@hooks';

import analyticsClient from '@utils/analytics';

import { RESTRICTION_END_HOUR } from '@services/date';

import colors from '@config/colors';
import IndicativePriceBox from '@atoms/blocks/IndicativePriceBox';
import RestrictedPeriodInfoBox from '@molecules/blocks/RestrictedPeriodInfoBox';
import DurationSummary from '@molecules/inputs/DurationSummary';
import MultipleChoice from '@molecules/inputs/MultipleChoice';
import DurationRadioButton from '@atoms/inputs/DurationRadioButton';

import { BaseDurationConfig, DurationOptions, Durations } from '@config/indicativeDurations';
import UpSellComponent from './UpSellComponent';

dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Europe/London');

interface StyledProps {
  bottomBorder: boolean;
}

const OuterContainer = styled.div`
  max-width: 400px;
`;

const OuterBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const Header = styled.div<StyledProps>`
  display: flex;
  justify-content: centre;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: ${({ bottomBorder }) => (bottomBorder ? '4px' : '4px 4px 0px 0px')};
  background-color: ${colors.teal};
  padding: 16px;
`;

const Body = styled.div`
  border-radius: 0 0 4px 4px;
  width: 100%;
  background-color: ${colors.grey.background.light};
  padding: 0 10px;
`;

const Text = styled.h4`
  font-family: 'proxima-soft';
  font-weight: 300;
  text-align: center;
`;

const PricePerHour = styled(Text)`
  color: ${colors.white};
  font-size: 16px;
  margin-top: 8px;
`;

const DurationNav = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const getTabOptions = (durationOptions: Durations): any => {
  const result: {
    text: string;
    value: string;
    locked: boolean;
    options: DurationOptions[];
  }[] = [];
  const keys = Object.keys(durationOptions) as Array<keyof Durations>;

  keys.map((key) => {
    if (key !== 'default') {
      result.push(durationOptions[key]);
    }
    return null;
  });
  return result;
};

export const containsSelectedDuration = (
  options: DurationOptions[],
  endChoiceSelected: string | null,
): boolean => options.find((option) => option.value === endChoiceSelected) !== undefined;

export const getOpenTab = (durations: Durations, endChoiceSelected: string | null): string => {
  const keys = Object.keys(durations) as Array<keyof Durations>;

  const filteredDurations = keys.filter((key) => {
    if (key !== 'default') {
      return containsSelectedDuration(durations[key].options, endChoiceSelected);
    }

    return false;
  });
  if (filteredDurations.length > 0) {
    return durations[filteredDurations[0]].value;
  }
  return 'hours';
};

interface Props {
  getQuickSelectPrices: (subscription: boolean) => void;
  disableQuickDateSelectionButton: boolean;
  durationsConfig: Durations;
  endChoiceSelected?: string | null;
  endDateTime: Dayjs;
  goToDurationOfCover: () => void;
  isLoadingPrices: boolean;
  isStartDateTimeBetween22and5: boolean;
  price: string;
  selectDuration: (duration: DurationOptions) => void;
  startDateTime: Dayjs;
  isSubscription: boolean;
  getCurrentRestrictedPeriod: () => void;
  isRestrictedPeriod: () => void;
  hidePrice?: boolean;
}

const IndicativePrice: React.FC<Props> = ({
  getQuickSelectPrices,
  durationsConfig,
  endChoiceSelected,
  endDateTime,
  goToDurationOfCover,
  isLoadingPrices,
  price = null,
  selectDuration,
  startDateTime,
  isSubscription,
  getCurrentRestrictedPeriod,
  isRestrictedPeriod,
  hidePrice = false,
}) => {
  const enableUpSell = useOptimizelyFlag('TEMP_INDICATIVE_PRICE_UP_SELL');

  const [durations, setDurations] = useState(durationsConfig);

  const [selectedTab, setSelectedTab] = useState(
    endChoiceSelected ? getOpenTab(durations, endChoiceSelected) : durations.default.tab,
  );

  const madeIndicativePriceRequest = useRef(false);

  useEffect(() => {
    if (madeIndicativePriceRequest.current) {
      return;
    }
    getQuickSelectPrices(isSubscription);
    setDurations(durationsConfig);
    setSelectedTab(
      endChoiceSelected
        ? getOpenTab(durationsConfig, endChoiceSelected)
        : durationsConfig.default.tab,
    );
    madeIndicativePriceRequest.current = true;
  }, [getQuickSelectPrices, isSubscription, setSelectedTab, endChoiceSelected, durationsConfig]);

  const durationOptions = () => {
    const selectedDuration = durations[selectedTab as keyof BaseDurationConfig];

    if ('options' in selectedDuration) {
      return selectedDuration.options.filter((option) => option.validate);
    }

    return [];
  };

  const tabOptions = getTabOptions(durations);

  const filteredTabOptions = () => {
    const restrictionStart = 20;

    const now = dayjs();

    const isOutsideRestriction = now.isBetween(
      now.hour(RESTRICTION_END_HOUR),
      now.hour(restrictionStart),
      null,
      '[)',
    );

    if (!isOutsideRestriction) return tabOptions.filter((elem: any) => elem.value !== 'hours');

    return tabOptions;
  };

  return (
    <OuterContainer data-amp-unmask>
      <OuterBox>
        <Header id='indicativePrice-container' bottomBorder={isSubscription}>
          {!hidePrice && (
            <IndicativePriceBox
              price={price}
              isLoading={isLoadingPrices}
              isSubscription={isSubscription}
            />
          )}
          <DurationSummary
            isSubscription={isSubscription}
            goToDurationOfCover={goToDurationOfCover}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
          {!hidePrice && (
            <PricePerHour
              id={`${
                isSubscription ? 'indicativePrice-text-tip' : 'indicativePrice-text-selectDuration'
              }`}
            >
              {`${
                isSubscription
                  ? "Tap 'starting now' to change start date/time"
                  : 'Select a duration to get a price'
              }`}
            </PricePerHour>
          )}
        </Header>
        {!isSubscription && (
          <Body>
            <DurationNav>
              <MultipleChoice
                id='indicativePrice-component-durationNavTabs'
                selectorType={undefined}
                onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
                  analyticsClient.trackEvent('web_selected_duration_tab', {
                    value: e.target.value,
                  });
                  setSelectedTab(e.target.value);
                }}
                options={filteredTabOptions()}
                value={selectedTab}
                squashed
              />
            </DurationNav>
            <MultipleChoice
              id='indicativePrice-component-durationNavOptions'
              selectorType={undefined}
              onSelect={(durationObject: DurationOptions) => {
                analyticsClient.trackEvent('web_selected_duration_option', {
                  value: durationObject.value,
                });
                selectDuration(durationObject);
              }}
              options={durationOptions()}
              customButton={DurationRadioButton}
              value={endChoiceSelected || durations.default.value}
              scrollable
              squashed
            />
            {selectedTab === 'months' && (
              <Info id='indicativePrice-text-infoMonthDuration'>
                To keep things simple, each month equals 30 days of cover
              </Info>
            )}
            <RestrictedPeriodInfoBox
              getCurrentRestrictedPeriod={getCurrentRestrictedPeriod}
              isRestrictedPeriod={isRestrictedPeriod}
            />
          </Body>
        )}
        {enableUpSell.enabled && !isSubscription && (
          <UpSellComponent
            durations={durations}
            updateQuote={(durationObject: DurationOptions) => selectDuration(durationObject)}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}
      </OuterBox>
    </OuterContainer>
  );
};

export default IndicativePrice;

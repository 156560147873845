import React from 'react';

import styled, { css, ThemeProvider } from 'styled-components';
import { ThemeUtils, Maintenance, StyleSheetManager } from '@rentecarlo/component-library';

import Loader from '@atoms/layout/Loader';
import size from '@config/size';
import Footer from '@molecules/layout/Footer.container';
import colors from '@config/colors';
import { useOptimizelyFlag } from '@hooks';
import { ComponentProps } from './App.container';

const { Veygo2024Theme } = ThemeUtils;

const ContentWrap = styled.div`
  background-color: ${colors.grey.background.lighter};
  flex-direction: column;
  flex-grow: 1;
  ${css`
    @media (min-width: ${size.desktopWidth}) {
      display: flex;
    }
  `}
`;

const RootContainer = styled.div`
  height: auto;
  min-height: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
`;

const App: React.FC<ComponentProps> = ({ loading, loadingPrimaryText, children }) => {
  const showMaintenancePage = useOptimizelyFlag('PERM_RAISE_QUOTE_MAINTENANCE_PAGE');

  return (
    // eslint-disable-next-line custom-rules/id-formats
    <StyleSheetManager enableVendorPrefixes>
      {showMaintenancePage.enabled ? (
        <Maintenance />
      ) : (
        <ThemeProvider theme={Veygo2024Theme}>
          <RootContainer id='root-container'>
            {loading && (
              <Loader
                id='loadingImg'
                primaryText={loadingPrimaryText}
                secondaryText='Please do not refresh the page'
              />
            )}
            <ContentWrap>
              {children}
              <Footer />
            </ContentWrap>
          </RootContainer>
        </ThemeProvider>
      )}
    </StyleSheetManager>
  );
};

export default App;

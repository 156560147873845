import Styled, { css, CSSProp } from 'styled-components';
import media from '@config/media';
import colors from '@config/colors';

import DownArrow from '@assets/down-arrow-icon.svg';

export const SummaryContainer = Styled.div`
  display: none;
  height: 36px;
  width: 100%;
  background: ${colors.robinsEggBlue};
  ${media.tablet`
    display: flex;
    align-items: center;
  `};
`;

export const IconWrapper = Styled.div`
  flex: 0.1%;
  justify-content: flex-end;
  display: flex;
  margin-right: 10px;
`;

type Visible = { visible: boolean };

export const ArrowIcon = Styled.img.attrs(() => ({
  src: DownArrow,
}))<Visible>`
  height: 10px;
  width: 14px;
  filter: brightness(100); /* make white */
  ${({ visible }: Visible): false | CSSProp =>
    visible &&
    css`
      transform: rotate(180deg);
    `};
`;

export const LabelWrapper = Styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 16px;
  flex: 2;
`;

export const Label = Styled.span`
  font-size: 16px;
  color: white;
  font-family: Graphie;
  font-weight: 600;
`;

export const PriceContainer = Styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 36px;
  width: 90px;
  background-color: ${({ theme }) => theme.textPrimaryAlt};
  align-items: center;
  color: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.08);
`;

export const CurrentPriceLabel = Styled.span`
  font-size: 10px;
  line-height: 8px;
  color: white;
  font-family: Graphie;
`;

export const CurrentPriceValue = Styled.span`
  font-size: 14px;
  color: white;
  font-family: proxima-soft;
  font-weight: bold;
`;

import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import QuoteStep from '@templates/QuoteStep';
import { ButtonCard } from '@rentecarlo/component-library';

import {
  getIPIDUrl,
  getPolicyBookletUrl,
  getBreakdownCoverFullTermsUrl,
  getBreakdownCoverIPIDUrl,
  getGuideUrl,
} from '@reselectors/files';

import { RenderFullContents } from '../../../config/importantInformation';

interface AlmostThereProps {
  isMobile: boolean;
  product: string;
  registrationNumber: string;
  excess: number;
  productName: string;
  ownerName: string;
  IPIDUrl: string;
  yourAgreementWithAbleUrl: string;
  policyBookletUrl: string;
  isDriverOwner: boolean;
  hasInsurance: string;
  isSubscription: boolean;
  goToPayment: () => void;
  hasBreakdownCover: boolean;
}

const AlmostThere: FC<AlmostThereProps> = ({
  product,
  isMobile,
  registrationNumber,
  excess,
  ownerName,
  yourAgreementWithAbleUrl,
  isDriverOwner,
  hasInsurance,
  isSubscription,
  goToPayment,
  hasBreakdownCover,
}) => {
  const policyBookletUrl = useSelector((state) => getPolicyBookletUrl(state));
  const IPIDUrl = useSelector((state) => getIPIDUrl(state));
  const breakdownIPIDURL = useSelector((state) => getBreakdownCoverIPIDUrl(state));
  const breakdownDetailsURL = useSelector((state) => getBreakdownCoverFullTermsUrl(state));
  const guideURL = useSelector((state) => getGuideUrl(state));

  const renderStepSubtitle = () =>
    'We know there is a lot of text, ' +
    'but we need you to read through all the information below, ' +
    'and confirm you are happy with it before we take payment.';

  const contents = RenderFullContents({
    registrationNumber,
    product,
    excess,
    ownerName,
    IPIDUrl,
    yourAgreementWithAbleUrl,
    policyBookletUrl,
    isDriverOwner,
    hasInsurance,
    isSubscription,
    hasBreakdownCover,
    breakdownDetailsURL,
    breakdownIPIDURL,
    guideURL,
  });

  return (
    <QuoteStep
      id='almostThere-component-quoteStep'
      stepTitle='About your cover'
      stepSubtitle={renderStepSubtitle()}
      form
    >
      <ButtonCard
        id='almostThere-component-singleTermsConfirmation'
        contents={contents}
        buttonText='Accept terms and purchase'
        onClick={() => goToPayment()}
        isMobile={isMobile}
      />
    </QuoteStep>
  );
};

export default AlmostThere;
